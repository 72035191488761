/*
Shade opacities map
Keys of shade color map must not be changed only the value can be adjusted here
*/
/*
Elevations map
Keys of elevations map must not be altered, these will result in variables and classes used across the entire project
eg: --elevation-1, --elevation-0, .elevation-2 etc
Values of elevations map can be altered following these rules:
    - all elevations bellow elevation-1 must use 'dark' color as base (with some level of opacity)
    - all elevations after elevation-1 must use 'light' color as base (with some level of opacity)
    - elevation-1 must be 0% opacity, leaving the base elevation color exposed without alteration
*/
/*
Shade opacities map
Keys of shade color map must not be changed only the value can be adjusted here
*/
/*
Elevations map
Keys of elevations map must not be altered, these will result in variables and classes used across the entire project
eg: --elevation-1, --elevation-0, .elevation-2 etc
Values of elevations map can be altered following these rules:
    - all elevations bellow elevation-1 must use 'dark' color as base (with some level of opacity)
    - all elevations after elevation-1 must use 'light' color as base (with some level of opacity)
    - elevation-1 must be 0% opacity, leaving the base elevation color exposed without alteration
*/
/*
Shade opacities map
Keys of shade color map must not be changed only the value can be adjusted here
*/
/*
Elevations map
Keys of elevations map must not be altered, these will result in variables and classes used across the entire project
eg: --elevation-1, --elevation-0, .elevation-2 etc
Values of elevations map can be altered following these rules:
    - all elevations bellow elevation-1 must use 'dark' color as base (with some level of opacity)
    - all elevations after elevation-1 must use 'light' color as base (with some level of opacity)
    - elevation-1 must be 0% opacity, leaving the base elevation color exposed without alteration
*/
/*  Function used to retrieve value from maps declared as variables in _vars.scss
    It returns either a value or another map
    Works the same as map-get (default map-getting function of sass)
    Use map-get instead
*/
.tooltip .tooltip-content.primary, .toast.primary, .badge.primary {
  background-color: rgb(var(--primary));
  color: rgb(var(--light));
}

.tooltip .tooltip-content.secondary, .toast.secondary, .badge.secondary {
  background-color: rgb(var(--dark));
  color: rgb(var(--light));
}

.tooltip .tooltip-content.success, .toast.success, .badge.success {
  background-color: rgb(var(--success));
  color: rgb(var(--light));
}

.tooltip .tooltip-content.warning, .toast.warning, .badge.warning {
  background-color: rgb(var(--warning));
  color: rgb(var(--light));
}

.tooltip .tooltip-content.danger, .toast.danger, .badge.danger {
  background-color: rgb(var(--danger));
  color: rgb(var(--light));
}

html, body {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  overscroll-behavior: none;
  overflow: hidden;
}

body {
  font-family: "Roboto", "Segoe UI", "San Francisco", sans-serif;
  font-size: 16px;
  line-height: 1.375em;
  color: rgb(var(--shade));
  background-color: var(--background);
  background-image: var(--background-gradient);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: auto;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: rgb(var(--primary));
  cursor: pointer;
}
a:hover, a:focus {
  text-decoration: underline;
  outline: none;
}

.standalone {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

@-webkit-keyframes gradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-moz-keyframes gradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-o-keyframes gradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes gradient {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes blur {
  0% {
    z-index: 90;
    opacity: 0.3;
  }
  70% {
    z-index: 90;
    opacity: 0.3;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}
@-moz-keyframes blur {
  0% {
    z-index: 90;
    opacity: 0.3;
  }
  70% {
    z-index: 90;
    opacity: 0.3;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}
@-o-keyframes blur {
  0% {
    z-index: 90;
    opacity: 0.3;
  }
  70% {
    z-index: 90;
    opacity: 0.3;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}
@keyframes blur {
  0% {
    z-index: 90;
    opacity: 0.3;
  }
  70% {
    z-index: 90;
    opacity: 0.3;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}
@-webkit-keyframes emphasys {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes emphasys {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes emphasys {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes emphasys {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-o-keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes growth {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
  100% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
}
@-moz-keyframes growth {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
  100% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
}
@-o-keyframes growth {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
  100% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
}
@keyframes growth {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
  100% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
}
@-webkit-keyframes shrink {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}
@-moz-keyframes shrink {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}
@-o-keyframes shrink {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}
@keyframes shrink {
  0% {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes toast {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  80% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes toast {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  80% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes toast {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  80% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes toast {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  80% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes toast-exit {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}
@-moz-keyframes toast-exit {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}
@-o-keyframes toast-exit {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}
@keyframes toast-exit {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-tweak {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 49px;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in-tweak {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 49px;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in-tweak {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 49px;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-tweak {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 49px;
  }
}
@-webkit-keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@-webkit-keyframes shake-vertical {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  80% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  90% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes shake-vertical {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  80% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  90% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
/**
* ----------------------------------------
* animation slide-top
* ----------------------------------------
*/
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-webkit-keyframes delay-overflow-auto {
  from {
    overflow: hidden;
  }
}
@keyframes delay-overflow-auto {
  from {
    overflow: hidden;
  }
}
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(30px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-700.woff2") format("woff2"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-700.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFDU+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-500.woff2") format("woff2"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-500.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFDU+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"), local("Roboto-Regular"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-400.woff2") format("woff2"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-400.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFDU+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-300.woff2") format("woff2"), url("./../../core/fonts/Roboto/roboto-latin-ext/roboto-latin-ext-300.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFDU+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "connect-font";
  src: url("../../fonts/centralIcons/connect_font.woff?4z9f8y") format("woff"), url("../../fonts/centralIcons/connect_font.eot?4z9f8y#iefix") format("embedded-opentype"), url("../../fonts/centralIcons/connect_font.ttf?4z9f8y") format("truetype"), url("../../fonts/centralIcons/connect_font.svg?4z9f8y#connect-font") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=connect_icon_],
[class*=" connect_icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "connect-font" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.connect_icon_forwarding:before {
  content: "\e900";
}

.connect_icon_key:before {
  content: "\e903";
}

.connect_icon_offers:before {
  content: "\e800";
}

.connect_icon_premium:before {
  content: "\e901";
}

.connect_icon_dashboard:before {
  content: "\e923";
}

.connect_icon_referral:before {
  content: "\e97b";
}

.connect_icon_referral_alt:before {
  content: "\e97c";
}

.connect_icon_phone_card:before {
  content: "\e97a";
}

.connect_icon_more_horizontal:before {
  content: "\e978";
}

.connect_icon_reset_wifi_password:before {
  content: "\e918";
}

.connect_icon_vulnerabilities_found:before {
  content: "\e91e";
}

.connect_icon_boot_options:before {
  content: "\e931";
}

.connect_icon_fix_apply:before {
  content: "\e971";
}

.connect_icon_optimization_start:before {
  content: "\e972";
}

.connect_icon_arrow-down-triangle:before {
  content: "\e973";
}

.connect_icon_check_outline:before {
  content: "\e974";
}

.connect_icon_lock_open:before {
  content: "\e975";
}

.connect_icon_private_data:before {
  content: "\e976";
}

.connect_icon_computer_setting:before {
  content: "\e977";
}

.connect_icon_launch:before {
  content: "\e909";
}

.connect_icon_phone_restricted:before {
  content: "\e90a";
}

.connect_icon_phone_shielded:before {
  content: "\e96a";
}

.connect_icon_disk_space:before {
  content: "\e96b";
}

.connect_icon_empty_page:before {
  content: "\e96c";
}

.connect_icon_user_setting:before {
  content: "\e96d";
}

.connect_icon_issues_flag:before {
  content: "\e96e";
}

.connect_icon_registry_keys:before {
  content: "\e96f";
}

.connect_icon_windows_update:before {
  content: "\e970";
}

.connect_icon_tips:before {
  content: "\e95f";
}

.connect_icon_no_scan:before {
  content: "\e960";
}

.connect_icon_quick_scan:before {
  content: "\e961";
}

.connect_icon_system_scan:before {
  content: "\e962";
}

.connect_icon_disconnected:before {
  content: "\e91a";
}

.connect_icon_connected:before {
  content: "\e919";
}

.connect_icon_info:before {
  content: "\e91b";
}

.connect_icon_safety_tips:before {
  content: "\e62b";
}

.connect_icon_unresolved:before {
  content: "\e916";
}

.connect_icon_unprotected:before {
  content: "\e915";
}

.connect_icon_protected:before {
  content: "\e917";
}

.connect_icon_error_triangle:before {
  content: "\e920";
}

.connect_icon_iot:before {
  content: "\e95c";
}

.connect_icon_linux:before {
  content: "\e963";
}

.connect_icon_ios:before {
  content: "\e95b";
}

.connect_icon_android:before {
  content: "\e616";
}

.connect_icon_apple:before {
  content: "\e617";
}

.connect_icon_osx:before {
  content: "\e617";
}

.connect_icon_mac:before {
  content: "\e617";
}

.connect_icon_windows:before {
  content: "\e618";
}

.connect_icon_ubuntu:before {
  content: "\e930";
}

.connect_icon_idtheft:before {
  content: "\e97d";
}

.connect_icon_dip:before {
  content: "\e946";
}

.connect_icon_password_manager:before {
  content: "\e97e";
}

.connect_icon_vpn:before {
  content: "\e97f";
}

.connect_icon_vpn_squircle:before {
  content: "\e964";
}

.connect_icon_vpn_squircle_outline:before {
  content: "\e965";
}

.connect_icon_protection_squircle:before {
  content: "\e966";
}

.connect_icon_protection_squircle_outline:before {
  content: "\e967";
}

.connect_icon_parental_squircle:before {
  content: "\e968";
}

.connect_icon_parental_squircle_outline:before {
  content: "\e969";
}

.connect_icon_generic_device:before {
  content: "\e950";
}

.connect_icon_user:before {
  content: "\e952";
}

.connect_icon_remove:before {
  content: "\e953";
}

.connect_icon_edit:before {
  content: "\e954";
}

.connect_icon_scan_page:before {
  content: "\e955";
}

.connect_icon_update:before {
  content: "\e957";
}

.connect_icon_community:before {
  content: "\e94b";
}

.connect_icon_clipboard:before {
  content: "\e949";
}

.connect_icon_exclamation_circle:before {
  content: "\e93f";
}

.connect_icon_question:before {
  content: "\e948";
}

.connect_icon_checkmark_circle:before {
  content: "\e94a";
}

.connect_icon_plus_circle:before {
  content: "\e979";
}

.connect_icon_external_link:before {
  content: "\e940";
}

.connect_icon_info_letter:before {
  content: "\e941";
}

.connect_icon_phone_call:before {
  content: "\e936";
}

.connect_icon_mail_open:before {
  content: "\e938";
}

.connect_icon_sphere:before {
  content: "\e93a";
}

.connect_icon_unknown:before {
  content: "\e935";
}

.connect_icon_session:before {
  content: "\e934";
}

.connect_icon_play:before {
  content: "\e933";
}

.connect_icon_camera:before {
  content: "\e932";
}

.connect_icon_time_limit:before {
  content: "\e92b";
}

.connect_icon_phone:before {
  content: "\e928";
}

.connect_icon_mail:before {
  content: "\e613";
}

.connect_icon_picture:before {
  content: "\e925";
}

.connect_icon_exclamation_mark:before {
  content: "\e926";
}

.connect_icon_threat:before {
  content: "\e91d";
}

.connect_icon_file:before {
  content: "\e601";
}

.connect_icon_help:before {
  content: "\e602";
}

.connect_icon_subscription:before {
  content: "\e605";
}

.connect_icon_notification:before {
  content: "\e606";
}

.connect_icon_parental:before {
  content: "\e607";
}

.connect_icon_success:before {
  content: "\e608";
}

.connect_icon_denied:before {
  content: "\e609";
}

.connect_icon_more:before {
  content: "\e60d";
}

.connect_icon_check:before {
  content: "\e626";
}

.connect_icon_close:before {
  content: "\e628";
}

.connect_icon_check_small:before {
  content: "\e614";
}

.connect_icon_close_small:before {
  content: "\e61d";
}

.connect_icon_plus:before {
  content: "\e615";
}

.connect_icon_download:before {
  content: "\e610";
}

.connect_icon_locate:before {
  content: "\e612";
}

.connect_icon_trash:before {
  content: "\e61c";
}

.connect_icon_desktop_large:before {
  content: "\e61e";
}

.connect_icon_mobile_large:before {
  content: "\e620";
}

.connect_icon_settings:before {
  content: "\e627";
}

.connect_icon_mobile:before {
  content: "\e629";
}

.connect_icon_account:before {
  content: "\e62c";
}

.connect_icon_area_large:before {
  content: "\e62d";
}

.connect_icon_logout:before {
  content: "\e62e";
}

.connect_icon_closed_large:before {
  content: "\e62f";
}

.connect_icon_closed:before {
  content: "\e630";
}

.connect_icon_search:before {
  content: "\e639";
}

.connect_icon_denied_box:before {
  content: "\e63b";
}

.connect_icon_eye:before {
  content: "\e63c";
}

.connect_icon_password:before {
  content: "\e63d";
}

.connect_icon_success_box:before {
  content: "\e640";
}

.connect_icon_interests:before {
  content: "\e645";
}

.connect_icon_calendar:before {
  content: "\e646";
}

.connect_icon_arrow_back:before {
  content: "\e801";
}

.connect_icon_arrow_next:before {
  content: "\e93b";
}

.connect_icon_error:before {
  content: "\e902";
}

.connect_icon_ceas:before {
  content: "\e907";
}

.connect_icon_right:before {
  content: "\e90d";
}

.connect_icon_left:before {
  content: "\e91f";
}

.connect_icon_up:before {
  content: "\e921";
}

.connect_icon_down:before {
  content: "\e922";
}

@font-face {
  font-family: "centralDevices";
  src: url("../../fonts/centralDevices/centralDevices.woff") format("woff"), url("../../fonts/centralDevices/centralDevices.eot?#iefix") format("embedded-opentype"), url("../../fonts/centralDevices/centralDevices.ttf") format("truetype"), url("../../fonts/centralDevices/centralDevices.svg#centralDevices") format("svg");
  font-weight: normal;
  font-style: normal;
}
.device-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "centralDevices" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box1:before {
  content: "\e93a";
}

.desktop:before {
  content: "\e93b";
}

.iot_hub:before {
  content: "\e93c";
}

.smart_display:before {
  content: "\e93d";
}

.wheather_sensor:before {
  content: "\e93e";
}

.set_top_box:before {
  content: "\e937";
}

.boxv2:before {
  content: "\e938";
}

.gps:before {
  content: "\e939";
}

.washing_machine:before {
  content: "\e902";
}

.vacuum:before {
  content: "\e903";
}

.smart_sprinkler:before {
  content: "\e905";
}

.smart_meter:before {
  content: "\e90e";
}

.sewing_machine:before {
  content: "\e911";
}

.smart_scale:before {
  content: "\e912";
}

.radar_detector:before {
  content: "\e928";
}

.doorphone:before {
  content: "\e929";
}

.other:before {
  content: "\e930";
}

.air_conditioning:before {
  content: "\e900";
}

.air_purifier:before {
  content: "\e901";
}

.baby_monitor:before {
  content: "\e904";
}

.car:before {
  content: "\e906";
}

.card_reader:before {
  content: "\e907";
}

.copyboard:before {
  content: "\e908";
}

.ereader:before {
  content: "\e909";
}

.extender:before {
  content: "\e90a";
}

.fridge:before {
  content: "\e90b";
}

.game_console:before {
  content: "\e90c";
}

.garage_door:before {
  content: "\e90d";
}

.health_device:before {
  content: "\e90f";
}

.home_monitoring:before {
  content: "\e910";
}

.tracking:before {
  content: "\e913";
}

.laptop:before {
  content: "\e914";
}

.mp3_player:before {
  content: "\e915";
}

.nas:before {
  content: "\e916";
}

.oven:before {
  content: "\e917";
}

.computer:before {
  content: "\e918";
}

.animal_tracker:before {
  content: "\e919";
}

.phone:before {
  content: "\e91a";
}

.pos:before {
  content: "\e91b";
}

.printer:before {
  content: "\e91c";
}

.prototyping_platform:before {
  content: "\e91d";
}

.router:before {
  content: "\e91e";
}

.sd_card:before {
  content: "\e91f";
}

.bed:before {
  content: "\e920";
}

.smart_doorbell:before {
  content: "\e921";
}

.smart_bulbs:before {
  content: "\e922";
}

.smart_doorlock:before {
  content: "\e923";
}

.generic_sensor:before {
  content: "\e924";
}

.smart_plug:before {
  content: "\e925";
}

.thermostat:before {
  content: "\e926";
}

.tv:before {
  content: "\e927";
}

.watch:before {
  content: "\e92a";
}

.solar_panel:before {
  content: "\e92b";
}

.speakers:before {
  content: "\e92c";
}

.streaming_device:before {
  content: "\e92d";
}

.switch:before {
  content: "\e92e";
}

.tablet:before {
  content: "\e92f";
}

.ups:before {
  content: "\e931";
}

.video_conferencing:before {
  content: "\e932";
}

.projector:before {
  content: "\e933";
}

.voip:before {
  content: "\e934";
}

.camera:before {
  content: "\e935";
}

.wireless_adapter:before {
  content: "\e936";
}

.badge {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 8px;
  box-sizing: content-box !important;
  border-radius: 2px;
  text-align: center;
  letter-spacing: 0.5px;
  vertical-align: middle;
}
.badge.circle {
  border-radius: 12px;
  min-width: 20px;
  padding-left: 0;
  padding-right: 0;
}
.badge.pill {
  border-radius: 12px;
  min-width: 20px;
  padding: 5px 12px;
}
.badge.empty {
  border-radius: 12px;
  padding: 6px 6px;
}
.badge.top, .badge.right, .badge.bottom, .badge.left {
  position: absolute;
}
.badge.top {
  top: 0;
}
.badge.right {
  right: 0;
}
.badge.bottom {
  bottom: 0;
}
.badge.left {
  left: 0;
}
.badge.top.center, .badge.bottom.center {
  left: 50%;
  transform: translateX(-50%);
}
.badge.right.center, .badge.left.center {
  top: 50%;
  transform: translateY(-50%);
}
.tag {
  display: inline-block;
  position: relative;
  padding: 0px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  border-radius: 12px;
  min-width: 24px;
}
.tag:has(.ui-icon.right) {
  padding-right: 4px;
}
.tag:has(.ui-icon.left) {
  padding-left: 4px;
}
.tag.tag-filled {
  color: rgb(var(--light));
}
.tag.tag-outline:before, .tag.tag-outline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  box-sizing: border-box;
}
.tag.tag-outline:before {
  border: 1px solid currentColor;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}
.tag.tag-outline:after {
  background: currentColor;
  opacity: 0.1;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  translate: 1px 1px;
}
.tag .tag-body {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-radius: inherit;
}
.tag .ui-icon.right {
  margin-left: 4px;
}
.tag .ui-icon.left {
  margin-right: 4px;
}

/*
Shade opacities map
Keys of shade color map must not be changed only the value can be adjusted here
*/
/*
Elevations map
Keys of elevations map must not be altered, these will result in variables and classes used across the entire project
eg: --elevation-1, --elevation-0, .elevation-2 etc
Values of elevations map can be altered following these rules:
    - all elevations bellow elevation-1 must use 'dark' color as base (with some level of opacity)
    - all elevations after elevation-1 must use 'light' color as base (with some level of opacity)
    - elevation-1 must be 0% opacity, leaving the base elevation color exposed without alteration
*/
/*  Function used to retrieve value from maps declared as variables in _vars.scss
    It returns either a value or another map
    Works the same as map-get (default map-getting function of sass)
    Use map-get instead
*/
.row .col, .row > [class*=col-] {
  position: relative;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
}

.container {
  width: 100%;
}
@media (min-width: 321px) {
  .container.constrained {
    max-width: 428px;
  }
}
@media (min-width: 429px) {
  .container.constrained {
    max-width: 540px;
  }
}
@media (min-width: 769px) {
  .container.constrained {
    max-width: 992px;
  }
}
@media (min-width: 1025px) {
  .container.constrained {
    max-width: 1000px;
  }
}
@media (min-width: 1281px) {
  .container.constrained {
    max-width: 1260px;
  }
}
@media (min-width: 1441px) {
  .container.constrained {
    max-width: 1300px;
  }
}

.row {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}
.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.row.no-gutters > .col, .row.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.row.spas-gutters {
  margin-left: -8px;
  margin-right: -8px;
}
.row.spas-gutters > .col, .row.spas-gutters > [class*=col-] {
  padding-right: 16px;
  padding-left: 16px;
}
.row.sm-gutters {
  margin-left: -6px;
  margin-right: -6px;
}
.row.sm-gutters > .col, .row.sm-gutters > [class*=col-] {
  padding-right: 6px;
  padding-left: 6px;
}
.row.xs-gutters {
  margin-left: -4px;
  margin-right: -4px;
}
.row.xs-gutters > .col, .row.xs-gutters > [class*=col-] {
  padding-right: 4px;
  padding-left: 4px;
}
.row > .col.transitioning, .row > [class*=col-].transitioning {
  transition: all 350ms;
}

.flex-column {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.col {
  -webkit-box-flex: 1 1 0%;
  -moz-box-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0 0 auto;
  -moz-box-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 8.3333333333%;
  -moz-box-flex: 8.3333333333%;
  -webkit-flex: 8.3333333333%;
  -ms-flex: 8.3333333333%;
  flex: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 16.6666666667%;
  -moz-box-flex: 16.6666666667%;
  -webkit-flex: 16.6666666667%;
  -ms-flex: 16.6666666667%;
  flex: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 25%;
  -moz-box-flex: 25%;
  -webkit-flex: 25%;
  -ms-flex: 25%;
  flex: 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 33.3333333333%;
  -moz-box-flex: 33.3333333333%;
  -webkit-flex: 33.3333333333%;
  -ms-flex: 33.3333333333%;
  flex: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 41.6666666667%;
  -moz-box-flex: 41.6666666667%;
  -webkit-flex: 41.6666666667%;
  -ms-flex: 41.6666666667%;
  flex: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 50%;
  -moz-box-flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 58.3333333333%;
  -moz-box-flex: 58.3333333333%;
  -webkit-flex: 58.3333333333%;
  -ms-flex: 58.3333333333%;
  flex: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 66.6666666667%;
  -moz-box-flex: 66.6666666667%;
  -webkit-flex: 66.6666666667%;
  -ms-flex: 66.6666666667%;
  flex: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 75%;
  -moz-box-flex: 75%;
  -webkit-flex: 75%;
  -ms-flex: 75%;
  flex: 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 83.3333333333%;
  -moz-box-flex: 83.3333333333%;
  -webkit-flex: 83.3333333333%;
  -ms-flex: 83.3333333333%;
  flex: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 91.6666666667%;
  -moz-box-flex: 91.6666666667%;
  -webkit-flex: 91.6666666667%;
  -ms-flex: 91.6666666667%;
  flex: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 100%;
  -moz-box-flex: 100%;
  -webkit-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  max-width: 100%;
}

@media (min-width: 321px) {
  .col-xs-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xs-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xs-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xs-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xs-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xs-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xs-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xs-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-xs-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-xs {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
@media (min-width: 429px) {
  .col-sm-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-sm {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
@media (min-width: 769px) {
  .col-md-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-md {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
@media (min-width: 1025px) {
  .col-lg-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-lg {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
@media (min-width: 1281px) {
  .col-xl-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-xl {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
@media (min-width: 1441px) {
  .col-xxl-1 {
    -webkit-box-flex: 8.3333333333%;
    -moz-box-flex: 8.3333333333%;
    -webkit-flex: 8.3333333333%;
    -ms-flex: 8.3333333333%;
    flex: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 16.6666666667%;
    -moz-box-flex: 16.6666666667%;
    -webkit-flex: 16.6666666667%;
    -ms-flex: 16.6666666667%;
    flex: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 25%;
    -moz-box-flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 33.3333333333%;
    -moz-box-flex: 33.3333333333%;
    -webkit-flex: 33.3333333333%;
    -ms-flex: 33.3333333333%;
    flex: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 41.6666666667%;
    -moz-box-flex: 41.6666666667%;
    -webkit-flex: 41.6666666667%;
    -ms-flex: 41.6666666667%;
    flex: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 50%;
    -moz-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 58.3333333333%;
    -moz-box-flex: 58.3333333333%;
    -webkit-flex: 58.3333333333%;
    -ms-flex: 58.3333333333%;
    flex: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 66.6666666667%;
    -moz-box-flex: 66.6666666667%;
    -webkit-flex: 66.6666666667%;
    -ms-flex: 66.6666666667%;
    flex: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 75%;
    -moz-box-flex: 75%;
    -webkit-flex: 75%;
    -ms-flex: 75%;
    flex: 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 83.3333333333%;
    -moz-box-flex: 83.3333333333%;
    -webkit-flex: 83.3333333333%;
    -ms-flex: 83.3333333333%;
    flex: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 91.6666666667%;
    -moz-box-flex: 91.6666666667%;
    -webkit-flex: 91.6666666667%;
    -ms-flex: 91.6666666667%;
    flex: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 100%;
    -moz-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .col-xxl {
    -webkit-box-flex: 1 1 0%;
    -moz-box-flex: 1 1 0%;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    max-width: 100%;
  }
}
.order-first {
  -webkit-box-ordinal-group: -1;
  -moz-box-ordinal-group: -1;
  -ms-flex-order: -1;
  -webkit-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 13;
  -moz-box-ordinal-group: 13;
  -ms-flex-order: 13;
  -webkit-order: 13;
  order: 13;
}

@media (min-width: 321px) {
  .order-xs-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-xs-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
@media (min-width: 429px) {
  .order-sm-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
@media (min-width: 769px) {
  .order-md-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
@media (min-width: 1025px) {
  .order-lg-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
@media (min-width: 1281px) {
  .order-xl-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
@media (min-width: 1441px) {
  .order-xxl-first {
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 13;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 13;
    -webkit-order: 13;
    order: 13;
  }
}
.panel {
  display: block;
  border-color: var(--list-divider);
}
.panel .panel-content {
  display: flex;
  flex-flow: column nowrap;
  border-color: inherit;
}
.panel .panel-header {
  flex: 1 0 60px;
  position: relative;
  outline: none;
  border-color: inherit;
}
.panel .panel-header [panel-header] {
  border-color: inherit;
}
.panel .panel-header.caret-left [panel-header] {
  padding-left: 60px;
}
.panel .panel-header.caret-left .caret {
  left: 0;
}
.panel .panel-header.caret-right [panel-header] {
  padding-right: 60px;
}
.panel .panel-header.caret-right .caret {
  right: 0;
}
.panel .panel-header .caret {
  position: absolute;
  width: 60px;
  height: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  translate: 0 -50%;
}
.panel .panel-header .caret:before {
  transition: transform 250ms ease-out;
}
.panel .panel-header:focus {
  outline: none;
}
.panel.expandable .panel-header {
  cursor: pointer;
}
.panel .panel-body {
  height: 0;
  transition: min-height 250ms ease;
  overflow-y: hidden;
}
.panel .panel-body:not(.open) {
  overflow-y: hidden;
}
.panel.bordered {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 16px;
}
.panel.bordered .panel-body {
  overflow-y: hidden;
}
.panel.bordered.collapsed {
  background: rgb(var(--primary), 0.05);
  border-color: rgb(var(--primary));
}

.panel-list .panel {
  padding: 0 24px;
  transition: background-color 250ms linear;
  overflow-y: hidden;
}
.panel-list .panel:first-child [panel-header] {
  border-top-style: solid;
  border-top-width: 1px;
}
.panel-list .panel [panel-header] {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.panel-list .panel .panel-header {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 56px;
  -moz-flex-basis: 56px;
  -ms-flex-basis: 56px;
  flex-basis: 56px;
}
.panel-list .panel .panel-header .caret {
  width: 20px;
  color: rgb(var(--shade-60));
  font-size: 14px;
}
.panel-list .panel:focus {
  outline: none;
}
@media (max-width: 428px) {
  .panel-list .panel {
    padding: 0 16px;
  }
}

.card {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  box-sizing: border-box;
  position: relative;
  font-size: 1em;
  border-radius: 4px;
  border-color: var(--card-divider);
  outline: none;
}
.card * {
  box-sizing: border-box;
}
.card.flat {
  background: var(--card-gradient, linear-gradient(rgb(var(--elevation-1)), rgb(var(--elevation-1)))), var(--card-background, rgb(var(--elevation)));
}
.card.raised {
  background: var(--card-gradient, linear-gradient(rgb(var(--elevation-1)), rgb(var(--elevation-1)))), var(--card-background, rgb(var(--elevation)));
  box-shadow: var(--card-shadow);
}
.card.empty {
  background: var(--card-gradient, linear-gradient(rgb(var(--elevation-0)), rgb(var(--elevation-0)))), var(--card-background, rgb(var(--elevation)));
  box-shadow: var(--card-shadow);
}
.card.bordered {
  border-width: 1px;
  border-style: solid;
}
.card.disabled {
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
}
.card.gray {
  background-color: var(--card-gradient, linear-gradient(rgb(var(--elevation-0)), rgb(var(--elevation-0)))), var(--card-background, rgb(var(--elevation)));
}
.card > .empty {
  background-color: var(--card-gradient, linear-gradient(rgb(var(--elevation-0)), rgb(var(--elevation-0)))), var(--card-background, rgb(var(--elevation)));
}
.card .card-header, .card .card-body, .card .card-footer {
  padding-left: 24px;
  padding-right: 24px;
  border-color: inherit;
}
.card .card-header,
.card .card-footer.bordered,
.card .card-footer.only-child,
.card .card-body.only-child,
.card .card-header.bordered + .card-body {
  padding-top: 24px;
}
.card .card-header.bordered,
.card .card-header.only-child,
.card .card-body,
.card .card-footer {
  padding-bottom: 24px;
}
.card .card-header {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: relative;
}
.card .card-header.bordered {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.card .card-header.flex-column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card .card-body {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  padding-top: 8px;
}
.card .card-body.empty {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-basis: auto;
  flex-basis: auto;
}
.card .card-body .card-text:not(:first-child) {
  margin-top: 8px;
}
.card .card-body .card-text:last-child {
  margin-bottom: 0;
}
.card .card-body.bordered {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.card .card-footer {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.card .card-footer.bordered {
  border-top-style: solid;
  border-top-width: 1px;
}
.card .top-shadowed, .card .bottom-shadowed {
  position: relative;
}
.card .top-shadowed:after, .card .bottom-shadowed:after {
  content: "";
  display: block;
  left: 0;
  right: 0;
  position: absolute;
  height: 7px;
  opacity: 0.25;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,e5e5e5+100&0.5+0,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(229, 229, 229, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(229, 229, 229, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(229, 229, 229, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#80000000", endColorstr="#00e5e5e5",GradientType=0 ); /* IE6-9 */
  z-index: 1;
}
.card .top-shadowed:after {
  top: 0;
}
.card .bottom-shadowed:after {
  bottom: 0;
  transform: rotate(180deg);
}
.card .top-bottom-shadowed {
  position: relative;
}
.card .top-bottom-shadowed:after {
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  left: -7px;
  right: -7px;
  position: absolute;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
.card .card-thumb {
  font-size: 32px;
  min-width: 1em;
  min-height: 1em;
  flex-shrink: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.card .card-thumb figure, .card .card-thumb img, .card .card-thumb .profile-picture {
  font-size: 12px;
}
.card .text {
  font-weight: normal;
  margin: 0;
}
.card .card-details {
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 30%;
  position: relative;
}
.card .card-actions {
  white-space: nowrap;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  align-items: center;
}
@media (max-width: 428px) {
  .card .card-header {
    flex-wrap: wrap;
  }
}

.card.clickable-card:hover, .card.clickable-card:focus {
  cursor: pointer;
  outline: none;
  border-color: rgb(var(--primary));
  background-color: rgb(var(--primary-5));
}

.drawer-viewport {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  height: 100%;
  position: relative;
  z-index: 0;
}
.drawer-viewport .drawer {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  overflow: hidden;
  max-width: 100%;
}
.drawer-viewport .drawer:focus {
  outline: none;
}
.drawer-viewport .drawer .drawer-content {
  min-width: 100%;
  overflow-y: auto;
  height: 100%;
}
.drawer-viewport .drawer.left {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}
.drawer-viewport .drawer.left.unfold {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.drawer-viewport .drawer.right {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.drawer-viewport .drawer.right.unfold {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}
.drawer-viewport .drawer.default {
  background: var(--drawer-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--drawer-background, rgb(var(--elevation)));
  color: var(--drawer-color);
}
.drawer-viewport .drawer.alt {
  background: var(--drawer-alt-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--drawer-alt-background, rgb(var(--elevation)));
  color: var(--drawer-alt-color);
}
.drawer-viewport .drawer-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--backdrop);
  top: 0;
  left: 0;
  border: 0 none;
  cursor: pointer;
  z-index: 0;
}
.drawer-viewport .drawer-main {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  position: relative;
  overflow: auto;
  z-index: 0;
}
.drawer-viewport .drawer.over {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: transform 250ms ease-out;
}
.drawer-viewport .drawer.over .drawer-content {
  max-width: 100%;
}
.drawer-viewport .drawer.over.left {
  left: 0;
  transform: translateX(-100%);
}
.drawer-viewport .drawer.over.right {
  right: 0;
  transform: translateX(100%);
}
.drawer-viewport .drawer.over.open {
  transform: translateX(0);
  z-index: 3;
}
.drawer-viewport .drawer.side {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0%;
  -moz-flex-basis: 0%;
  -ms-flex-basis: 0%;
  flex-basis: 0%;
  transition: flex 250ms ease-out;
}
.drawer-viewport .drawer.side.open {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  z-index: 3;
}
.drawer-viewport .drawer.push {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0%;
  -moz-flex-basis: 0%;
  -ms-flex-basis: 0%;
  flex-basis: 0%;
  transition: flex 250ms ease-out;
}
.drawer-viewport .drawer.push.open {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  z-index: 3;
}
.drawer-viewport .drawer.push + .drawer-main {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.drawer-viewport.displace > .drawer {
  z-index: 3;
}
.drawer-viewport.displace > .drawer-main {
  width: 100%;
  flex-shrink: 0;
}
.drawer-viewport.displace.left {
  justify-content: flex-start;
}
.drawer-viewport.displace.right {
  justify-content: flex-end;
}

::ng-deep .onboarding-dip + .modal-backdrop {
  background-color: var(--background);
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  color: var(--backdrop);
}
.modal.show {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  z-index: 5;
}
.modal.animating {
  overflow: hidden;
  pointer-events: none;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: currentColor;
}
.modal-backdrop.dismissable {
  cursor: pointer;
}

.modal-dialog {
  z-index: 1;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-align-self: center;
  align-self: center;
  margin: auto;
  position: relative;
  color: var(--modal-color);
  font-size: 16px;
  max-width: 100%;
  max-height: 100%;
}
.modal-dialog.flow {
  overflow: hidden;
  background: var(--modal-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--modal-background, rgb(var(--elevation)));
  box-shadow: var(--modal-shadow);
}
.modal-dialog.single:not(.video-dialog) .modal-component {
  background: var(--modal-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--modal-background, rgb(var(--elevation)));
  box-shadow: var(--modal-shadow);
}
.modal-dialog .modal-component {
  display: block;
  border-radius: inherit;
  max-width: 100vw;
  max-height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
@media (min-width: 429px) {
  .modal-dialog .modal-component.size-sm {
    width: 440px;
  }
  .modal-dialog .modal-component.size-md {
    width: 600px;
  }
  .modal-dialog .modal-component.size-lg {
    width: 790px;
  }
  .modal-dialog .modal-component.size-xl {
    width: 880px;
  }
  .modal-dialog .modal-component.size-xxl {
    width: 960px;
  }
  .modal-dialog .modal-component.size-auto {
    width: auto;
  }
}
.modal-dialog.video-dialog {
  height: auto;
}
.modal-dialog.video-dialog .modal-close {
  position: relative;
  display: block;
  margin-left: auto;
  color: rgb(var(--light));
}
.modal-dialog.light-close .modal-close {
  color: rgb(var(--light));
}

.modal.mobile .modal-dialog {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.modal.mobile .modal-dialog .modal-component {
  width: 100%;
  height: 100%;
}
.modal.mobile .modal-dialog.bottom-sheet {
  height: auto;
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  align-self: flex-end;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal.mobile .modal-dialog.bottom-sheet .modal-component {
  height: auto;
}

.modal .modal-close {
  width: 40px;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: env(safe-area-inset-top);
  z-index: 2;
}
.modal .modal-content {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  height: 100%;
  max-height: 100vh;
}
.modal .modal-header, .modal .modal-body, .modal .modal-footer {
  padding: 16px;
}
.modal .modal-header {
  flex-shrink: 0;
}
.modal .modal-header.bordered {
  border-bottom: 1px solid var(--modal-divider);
}
.modal .modal-header:not(.bordered) {
  padding-bottom: 0;
}
.modal .modal-body {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  overflow-y: auto;
}
.modal .modal-body.centered {
  margin-top: auto;
  margin-bottom: auto;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
}
.modal .modal-footer {
  flex-shrink: 0;
}
.modal .modal-footer.bordered {
  border-top: 1px solid var(--modal-divider);
}
.modal .modal-footer .btn {
  min-width: 110px;
}
.modal .modal-footer .btn-extend {
  width: 100%;
}
.modal .modal-title {
  margin: 0;
  font-weight: 500;
  line-height: 1em;
}
.modal .bg-gray {
  background-color: rgb(var(--shade-5));
}
.modal .shadowed {
  box-shadow: inset 0 2px 8px -2px rgba(0, 0, 0, 0.1);
}
@media (min-width: 429px) {
  .modal .modal-dialog {
    border-radius: 5px;
  }
  .modal .modal-header, .modal .modal-body, .modal .modal-footer {
    padding: 24px;
  }
  .modal .modal-footer .btn + .btn {
    margin-left: 12px;
  }
}
@media (min-width: 769px) {
  .modal .modal-dialog {
    border-radius: 8px;
  }
  .modal .modal-header, .modal .modal-body, .modal .modal-footer {
    padding: 32px;
  }
  .modal .modal-footer .btn + .btn {
    margin-left: 16px;
  }
}

.modal-dialog .illustrated-modal-container {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  height: calc(100% + env(safe-area-inset-top));
  margin-top: calc(env(safe-area-inset-top) * -1);
}
.modal-dialog .illustrated-modal-container.flex-column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal-dialog .illustrated-modal-container .modal-illustration {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-basis: 50%;
  flex-basis: 50%;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  overflow: hidden;
  position: relative;
}
.modal-dialog .illustrated-modal-container .modal-illustration .illustration {
  max-width: 100%;
}
.modal-dialog .illustrated-modal-container .modal-content {
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

.toasts {
  font-size: 1em;
  position: absolute;
  margin: 8px;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  z-index: 6;
  right: 0;
  bottom: 0;
  flex-direction: column-reverse;
}
.toasts[position*=top] {
  top: 0;
  bottom: auto;
  flex-direction: column;
}
.toasts[position*=left] {
  left: 0;
  right: auto;
}
.toasts .toast + .toast {
  margin-bottom: 8px;
}

.toast {
  border-radius: 4px;
  background: rgb(var(--dark));
  color: rgb(var(--light));
  padding: 0 8px;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  transition: all 0.15s ease-in;
}
.toast .toast-inner {
  padding: 8px;
}
.toast .toast-inner p {
  margin: 0;
  color: inherit;
}
.toast .toast-close {
  padding: 8px;
  cursor: pointer;
}

.toolbar {
  font-size: 1rem;
  padding: 0 8px;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  height: calc(54px + env(safe-area-inset-top));
}
.toolbar.default {
  background: var(--toolbar-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--toolbar-background, rgb(var(--elevation)));
  color: var(--toolbar-color, rgb(var(--shade)));
}
.toolbar.alt {
  background: var(--toolbar-alt-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--toolbar-alt-background, rgb(var(--elevation)));
  color: var(--toolbar-alt-color, rgb(var(--shade)));
}
@media (min-width: 321px) {
  .toolbar {
    height: calc(54px + env(safe-area-inset-top));
  }
}
@media (min-width: 429px) {
  .toolbar {
    height: calc(65px + env(safe-area-inset-top));
  }
}
@media (min-width: 769px) {
  .toolbar {
    height: calc(80px + env(safe-area-inset-top));
  }
}
.toolbar .toolbar-logo {
  width: 100%;
  min-width: 130px;
  max-width: 240px;
}
.toolbar .toolbar-logo img {
  width: 100%;
}
.toolbar .icon {
  font-size: 21px;
}
.toolbar .toolbar-content {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-basis: auto;
  flex-basis: auto;
  height: 100%;
  align-items: center;
  padding: 0 8px;
}
.toolbar .toolbar-content.left {
  justify-content: flex-start;
  text-align: left;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  overflow-x: auto;
}
.toolbar .toolbar-content.right {
  justify-content: flex-end;
  text-align: right;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.toolbar .toolbar-content.center {
  justify-content: center;
  text-align: center;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
}

.tooltip {
  position: fixed;
  max-width: 100%;
  border: 0 none;
  z-index: 6;
}
.tooltip .tooltip-content {
  padding: 8px 12px;
  display: inline-block;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  border-radius: 4px;
  width: 100%;
}
.tooltip .tooltip-content:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: inherit;
  transform: rotate(45deg) translate(-70%, -10%);
  position: absolute;
}
.tooltip.top {
  transform: translate(-50%, -100%);
  margin-top: -8px;
}
.tooltip.top .tooltip-content:before {
  top: 100%;
  left: 50%;
}
.tooltip.bottom {
  transform: translate(-50%, 0%);
  margin-top: 8px;
}
.tooltip.bottom .tooltip-content:before {
  top: 0;
  left: 50%;
}
.tooltip.left {
  transform: translate(-100%, -50%);
  margin-left: -8px;
}
.tooltip.left .tooltip-content:before {
  top: 50%;
  left: 100%;
}
.tooltip.right {
  transform: translate(0%, -50%);
  margin-left: 8px;
}
.tooltip.right .tooltip-content:before {
  top: 50%;
  left: 0;
}

.chart-tooltip {
  position: absolute;
  opacity: 1;
  pointer-events: none;
  background-color: rgb(var(--primary-5));
  border: 1px solid rgb(var(--primary));
  border-radius: 8px;
  padding: 8px;
}
.chart-tooltip .chart-tooltip-title,
.chart-tooltip .chart-tooltip-body {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.flex-list {
  list-style: inside none;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.flex-list.column hr {
  flex: 1 1 100%;
  margin: 16px 0;
}
.flex-list.row {
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.flex-list.row hr {
  height: 100%;
  margin: 0 16px;
}
.flex-list.boxed {
  border: 1px solid;
  border-color: inherit;
}
.flex-list hr {
  opacity: 1;
}
.flex-list.default {
  border-color: var(--list-divider);
}
.flex-list.default.highlights .list-item:not(.list-item-footer):hover, .flex-list.default.highlights .list-item:not(.list-item-footer).active, .flex-list.default.highlights .list-item:not(.list-item-footer):focus {
  color: rgb(var(--shade));
  background-color: var(--list-h-background);
  background-image: var(--list-h-gradient);
  outline: none;
}
.flex-list.nav {
  border-color: var(--list-nav-divider);
}
.flex-list.nav.highlights .list-item:not(.list-item-footer):hover, .flex-list.nav.highlights .list-item:not(.list-item-footer).active, .flex-list.nav.highlights .list-item:not(.list-item-footer):focus {
  color: rgb(var(--primary));
  background-color: var(--list-nav-h-background);
  background-image: var(--list-nav-h-gradient);
  outline: none;
}
.flex-list a.list-item {
  cursor: pointer;
  color: inherit;
}
.flex-list a.list-item a.active {
  color: rgb(var(--primary));
}
.flex-list.unbordered .list-item + .list-item:not(.list-item-separator) {
  border-top-width: 0;
  border-top-style: none;
}
.flex-list .list-item + .list-item {
  border-top-width: 1px;
  border-top-style: solid;
}
.flex-list.row .list-item + .list-item {
  border-top: none;
  border-left-width: 1px;
  border-left-style: solid;
}
.flex-list .list-item {
  margin: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;
  text-align: left;
  transition: background-color 200ms ease-out, color 150ms ease-in;
  line-height: 1.375em;
  line-height: 1.375em;
  border-color: inherit;
}
.flex-list .list-item.disabled-actions {
  pointer-events: none;
  opacity: 0.5;
}
.flex-list .list-item.column {
  flex-flow: column nowrap;
  align-items: flex-start;
}
.flex-list .list-item.list-item-separator {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  border-top-width: 1px;
  border-top-style: solid;
}
.flex-list .list-item.list-item-separator:hover {
  background: none !important;
}
.flex-list .list-item .title {
  display: block;
  line-height: 1.15em;
  margin: 0;
}
.flex-list .list-item .thumb,
.flex-list .list-item .icon {
  line-height: inherit;
}
.flex-list .list-item .thumb {
  font-size: 48px;
}
.flex-list .list-item .thumb img {
  font-size: 14px;
}
.flex-list .list-item .details {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 8.3333333333%;
  line-height: inherit;
}
.flex-list .list-item .details.span-2 {
  flex-grow: 2;
  flex-basis: 16.6666666667%;
}
.flex-list .list-item .details.span-3 {
  flex-grow: 3;
  flex-basis: 25%;
}
.flex-list .list-item .details.span-4 {
  flex-grow: 4;
  flex-basis: 33.3333333333%;
}
.flex-list .list-item .details.span-5 {
  flex-grow: 5;
  flex-basis: 41.6666666667%;
}
.flex-list .list-item .details.span-6 {
  flex-grow: 6;
  flex-basis: 50%;
}
.flex-list .list-item .details.span-7 {
  flex-grow: 7;
  flex-basis: 58.3333333333%;
}
.flex-list .list-item .details.span-8 {
  flex-grow: 8;
  flex-basis: 66.6666666667%;
}
.flex-list .list-item .details.span-9 {
  flex-grow: 9;
  flex-basis: 75%;
}
.flex-list .list-item .details.span-10 {
  flex-grow: 10;
  flex-basis: 83.3333333333%;
}
.flex-list .list-item .details.span-11 {
  flex-grow: 11;
  flex-basis: 91.6666666667%;
}
.flex-list .list-item .details .title {
  font-weight: 500;
}
.flex-list .list-item .details .title + p {
  line-height: 1.3;
}
.flex-list .list-item .details p {
  margin: 0;
  line-height: inherit;
}
.flex-list .list-item.disabled {
  background-color: rgb(var(--shade-5));
  opacity: 0.9;
  cursor: default;
}
.flex-list .list-item.unbordered {
  border: none;
}

.flex-list .list-item {
  padding: 8px;
}
.flex-list .list-item .details {
  padding: 8px;
}
.flex-list .list-item thumb,
.flex-list .list-item .thumb,
.flex-list .list-item .icon {
  margin: 8px;
}
@media (min-width: 769px) {
  .flex-list.list-lg .list-item {
    padding: 16px;
  }
  .flex-list.list-lg .list-item .icon {
    font-size: 1.2em;
  }
  .flex-list.list-lg .list-item.column {
    padding: 24px 0;
  }
  .flex-list.list-sm .list-item {
    padding: 4px;
  }
  .flex-list.list-sm .list-item .details {
    padding: 4px;
  }
  .flex-list.list-sm .list-item thumb,
  .flex-list.list-sm .list-item .thumb,
  .flex-list.list-sm .list-item .icon {
    margin: 4px;
  }
}

.flex-list.cards-list .card:not(:first-child) {
  margin-top: 16px;
}

nav a {
  text-decoration: none;
}
nav .active {
  color: rgb(var(--primary));
}

.tabs {
  display: flex;
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.tabs::-webkit-scrollbar {
  display: none;
}
.tabs nav {
  height: 100%;
  transition: transform 150ms ease-out;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.tabs nav .tab {
  height: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}
.tabs .scroll-tab {
  position: absolute;
}
.tabs .scroll-tab.left {
  left: 0;
}
.tabs .scroll-tab.right {
  right: 0;
}
.tabs .tab {
  padding: 0 16px;
  transition: all 150ms ease-out;
}
.tabs .tab:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  color: inherit;
  background: currentColor;
  transform: translateY(100%);
}
.tabs .tab.active {
  position: relative;
}
.tabs .tab.active:before {
  transform: translateY(0);
  transition: transform 250ms 50ms;
}

.tabs .tab {
  color: rgb(var(--primary));
}
.tabs .tab:hover, .tabs .tab.active {
  color: rgb(var(--primary));
  background-color: rgb(var(--primary), 0.05);
}
.tabs .tab:active {
  background-color: rgb(var(--primary), 0.05);
}

.dashboard {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.dashboard .dashboard-header {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  flex-shrink: 0;
  width: 100%;
  z-index: 1;
}
.dashboard .dashboard-header .toolbar {
  max-height: 64px;
}
.dashboard .dashboard-body {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding: 12px;
}
.dashboard .dashboard-empty-state {
  margin: auto;
}
@media (min-width: 769px) {
  .dashboard .dashboard-empty-state .empty-state i, .dashboard .dashboard-empty-state .empty-state .icon {
    font-size: 80px;
  }
  .dashboard .dashboard-empty-state .empty-state .title {
    font-size: 1.6em;
  }
}
.dashboard .dashboard-body-header {
  margin-bottom: 8px;
}
@media (min-width: 429px) {
  .dashboard .dashboard-body-header {
    margin-bottom: 16px;
  }
}
.dashboard .dashboard-body-header .dashboard-title {
  margin: 0;
}
.dashboard .dashboard-body-header .dashboard-lead {
  font-size: 1em;
  margin-top: -4px;
}
@media (min-width: 429px) {
  .dashboard .dashboard-body {
    padding: 16px;
  }
}
@media (min-width: 769px) {
  .dashboard .dashboard-body {
    padding: 32px;
  }
}

.form-control .input-control, .form-control .select-control, .form-control .textarea-control {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0;
  color: rgb(var(--shade));
}
.form-control .boxed.input-control, .form-control .boxed.select-control, .form-control .boxed.textarea-control {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgb(var(--shade-40));
  background-color: rgb(var(--elevation));
}

.form-control input, .form-control select, .form-control textarea {
  width: 100%;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px;
  color: inherit;
  border: none;
  border-radius: inherit;
  background: none;
  caret-color: currentColor;
  text-align: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  outline: none;
}
.form-control input::placeholder, .form-control select::placeholder, .form-control textarea::placeholder {
  color: rgb(var(--shade-60));
  opacity: 1;
}
.form-control input:-ms-input-placeholder, .form-control select:-ms-input-placeholder, .form-control textarea:-ms-input-placeholder {
  color: rgb(var(--shade-60));
}
.form-control input::-ms-input-placeholder, .form-control select::-ms-input-placeholder, .form-control textarea::-ms-input-placeholder {
  color: rgb(var(--shade-60));
}
.form-control input::-ms-reveal, .form-control select::-ms-reveal, .form-control textarea::-ms-reveal {
  display: none;
}

.range-slider label, .uploadfile-control label, .form-control label {
  color: rgb(var(--shade-80));
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-control label, .checkbox-control label, .switch-control label {
  color: rgb(var(--shade-80));
  font-size: 16px;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-control .hint, .checkbox-control .hint, .switch-control .hint, .form-control .hint {
  color: rgb(var(--shade-60));
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-control.sm input, .form-control.sm select, .form-control.sm textarea {
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-control.md input, .form-control.md select, .form-control.md textarea {
  font-size: 14px;
  line-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.form-control.lg input, .form-control.lg select, .form-control.lg textarea {
  font-size: 16px;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.form-control.xl input, .form-control.xl select, .form-control.xl textarea {
  font-size: 16px;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

*[sr-only] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form-control {
  display: inline-block;
  margin-bottom: 16px;
}
.form-control label {
  margin-bottom: 4px;
  display: inline-block;
}
.form-control .hint {
  margin-top: 4px;
}
.form-control.sm select {
  padding-right: 32px;
}
.form-control.sm .select-control > .icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 0;
}
.form-control.sm .select-control .option {
  height: 32px;
  font-size: 14px;
}
.form-control.sm.pill .input-control {
  border-radius: 32px;
}
.form-control.md select {
  padding-right: 36px;
}
.form-control.md .select-control > .icon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin: 0;
}
.form-control.md .select-control .option {
  height: 36px;
  font-size: 14px;
}
.form-control.md.pill .input-control {
  border-radius: 36px;
}
.form-control.lg select {
  padding-right: 40px;
}
.form-control.lg .select-control > .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0;
}
.form-control.lg .select-control .option {
  height: 40px;
  font-size: 16px;
}
.form-control.lg.pill .input-control {
  border-radius: 40px;
}
.form-control.xl select {
  padding-right: 44px;
}
.form-control.xl .select-control > .icon {
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
}
.form-control.xl .select-control .option {
  height: 44px;
  font-size: 16px;
}
.form-control.xl.pill .input-control {
  border-radius: 44px;
}
.form-control.readonly .input-control, .form-control.readonly .select-control, .form-control.readonly .textarea-control {
  color: rgb(var(--shade));
  border-color: rgb(var(--shade-20));
  background-color: rgb(var(--elevation));
}
.form-control.readonly .hint {
  color: rgb(var(--shade-60));
}
.form-control.disabled .input-control, .form-control.disabled .select-control, .form-control.disabled .textarea-control {
  color: rgb(var(--shade-60));
  border-color: rgb(var(--shade-20));
  background-color: rgb(var(--shade-5));
}
.form-control.disabled .hint {
  color: rgb(var(--shade-60));
}
.form-control.invalid .input-control, .form-control.invalid .select-control, .form-control.invalid .textarea-control {
  color: rgb(var(--shade));
  border-color: rgb(var(--danger));
  background-color: rgb(var(--a7-5));
}
.form-control.invalid .hint {
  color: rgb(var(--danger));
}
.form-control.focused label {
  color: rgb(var(--primary));
}
.form-control.focused .input-control, .form-control.focused .select-control, .form-control.focused .textarea-control {
  border-color: rgb(var(--primary));
}

.input-control {
  position: relative;
}
.input-control .suffix, .input-control .prefix {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1em;
  z-index: 2;
  margin-top: -1px;
  margin-bottom: -1px;
}
.input-control .suffix {
  order: 12;
  margin-right: -1px;
}
.input-control .prefix {
  order: -1;
  margin-left: -1px;
}
.input-control.prefixed input {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-control.suffixed input {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-control {
  position: relative;
  width: 100%;
}
.select-control > .icon {
  cursor: pointer;
  position: absolute;
  text-align: center;
  right: 0;
  top: 50%;
  transform: rotate(-90deg) translateX(50%);
  font-size: 13px;
  color: rgb(var(--shade-40));
  pointer-events: none;
}
.select-control input {
  padding-right: 48px;
}
.select-control select {
  z-index: 1;
  -webkit-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
}
.select-control select.withThumb, .select-control select.withThumb + .select-options .option {
  background-repeat: no-repeat;
  background-position: 8px;
  background-size: 24px;
  padding-left: 40px;
}
.select-control select::-ms-expand {
  display: none;
}
.select-control .select-options {
  position: absolute;
  z-index: 0;
  left: 0;
  overflow-y: auto;
  height: 0;
  min-width: calc(100% - 2px);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(var(--primary));
  border-radius: 4px;
  box-shadow: 0px 2px 8px 2px rgba(0, 94, 255, 0.1);
  background-color: rgb(var(--elevation));
  box-sizing: content-box;
}
.select-control .select-options.down {
  top: 100%;
  margin-top: 1px;
}
.select-control .select-options.up {
  bottom: 100%;
  margin-bottom: 1px;
}
.select-control .select-options.open {
  height: auto;
  z-index: 3;
}
.select-control .select-options .option {
  display: block;
  text-align: left;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-control .select-options .option.active, .select-control .select-options .option.selected {
  background: rgb(var(--primary), 0.1);
  padding-right: 36px;
}
.select-control .select-options .option.active:after, .select-control .select-options .option.selected:after {
  content: "";
  display: block;
  color: rgb(var(--primary));
  position: absolute;
  right: 12px;
  margin-top: -6px;
  top: 50%;
  width: 10px;
  height: 5px;
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.select-control .select-options .option.hidden {
  display: none;
}
.select-control .select-options .option[disabled] {
  color: rgb(var(--shade-40));
  pointer-events: none;
}
.select-control .select-options .option:hover, .select-control .select-options .option:focus {
  background: rgb(var(--primary), 0.05);
}

.textarea-control textarea {
  height: auto;
}
.textarea-control textarea.no-resize {
  resize: none;
}

.radio-control, .checkbox-control, .switch-control {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  position: relative;
  z-index: 0;
}
.radio-control label, .checkbox-control label, .switch-control label {
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
}
.radio-control label[for], .checkbox-control label[for], .switch-control label[for] {
  cursor: pointer;
}
.radio-control input, .checkbox-control input, .switch-control input {
  position: absolute;
  height: 24px;
  margin: 6px 0;
  z-index: 0;
  opacity: 0;
  cursor: pointer;
}
.radio-control input[disabled] ~ label, .radio-control input[disabled] ~ .control-div, .checkbox-control input[disabled] ~ label, .checkbox-control input[disabled] ~ .control-div, .switch-control input[disabled] ~ label, .switch-control input[disabled] ~ .control-div {
  cursor: default;
}
.radio-control .hint, .checkbox-control .hint, .switch-control .hint {
  margin-top: 0;
}

.checkbox-control input, .radio-control input {
  width: 24px;
}
.checkbox-control label, .radio-control label {
  display: block;
}
.checkbox-control .hint, .radio-control .hint {
  margin: 0;
}
.checkbox-control .control-label, .radio-control .control-label {
  margin-left: 12px;
  width: 100%;
}
.checkbox-control .control-div, .radio-control .control-div {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 6px 0;
  min-width: 24px;
  width: 24px;
  height: 24px;
  background: transparent;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgb(var(--shade-60));
  border-style: solid;
  z-index: -1;
  transition: opacity 0.05s ease-in-out;
  position: relative;
}
.checkbox-control .control-div ui-icon, .radio-control .control-div ui-icon {
  opacity: 0;
}
.checkbox-control.invalid .hint, .radio-control.invalid .hint {
  color: rgb(var(--danger));
}
.checkbox-control.invalid .control-div, .radio-control.invalid .control-div {
  border-color: rgb(var(--danger));
}
.checkbox-control input:checked ~ .control-div, .radio-control input:checked ~ .control-div {
  background: rgb(var(--primary));
  border-width: 0px;
}
.checkbox-control input:checked ~ .control-div ui-icon, .radio-control input:checked ~ .control-div ui-icon {
  opacity: 1;
}
.checkbox-control input:focus, .radio-control input:focus {
  border-width: 2px;
}
.checkbox-control input:focus ~ .control-div, .radio-control input:focus ~ .control-div {
  border-color: rgb(var(--primary-20));
}
.checkbox-control input:focus:checked ~ .control-div, .radio-control input:focus:checked ~ .control-div {
  background: rgb(var(--primary));
  border-color: rgb(var(--primary-20));
}
.checkbox-control input:hover ~ .control-div, .radio-control input:hover ~ .control-div {
  border-color: rgb(var(--primary));
}
.checkbox-control input:hover:checked ~ .control-div, .radio-control input:hover:checked ~ .control-div {
  background: linear-gradient(rgb(var(--dark), 0.2), rgb(var(--dark), 0.2)), linear-gradient(rgb(var(--primary)), rgb(var(--primary)));
}
.checkbox-control input:active ~ .control-div, .radio-control input:active ~ .control-div {
  border-color: rgb(var(--primary), 0.6);
}
.checkbox-control input:active:checked ~ .control-div, .radio-control input:active:checked ~ .control-div {
  background: linear-gradient(rgb(var(--dark), 0.4), rgb(var(--dark), 0.4)), linear-gradient(rgb(var(--primary)), rgb(var(--primary)));
}
.checkbox-control input:disabled ~ .control-div, .radio-control input:disabled ~ .control-div {
  border-color: rgb(var(--shade-60));
  opacity: 0.4;
}
.checkbox-control input:disabled:checked ~ .control-div, .radio-control input:disabled:checked ~ .control-div {
  background: rgb(var(--primary));
  opacity: 0.4;
}
.checkbox-control.ltr, .radio-control.ltr {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.checkbox-control.ltr .control-div, .radio-control.ltr .control-div {
  margin-right: 12px;
}
.checkbox-control.rtl, .radio-control.rtl {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}
.checkbox-control.rtl .control-div, .radio-control.rtl .control-div {
  margin-left: 12px;
  -webkit-box-ordinal-group: 12;
  -moz-box-ordinal-group: 12;
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.radio-control .control-div {
  border-radius: 50%;
}

.radio-control.image input, .radio-control.image div.control-div {
  position: absolute;
  width: 57px;
  height: 57px;
  border-radius: 50%;
}
.radio-control.image div.control-div {
  border-width: 0px;
  color: rgb(var(--primary));
  background: transparent;
}
.radio-control.image div.control-div ui-icon {
  display: none;
}
.radio-control.image div.control-label {
  margin: 0;
}
.radio-control.image label {
  padding: 0;
  margin: 6px 0;
  border-radius: 50%;
  width: 57px;
  height: 57px;
  border-width: 1px;
  border-color: rgb(var(--light));
  border-style: solid;
}
.radio-control.image label .ui-avatar {
  z-index: -1;
}
.radio-control.image input:checked ~ div.control-div,
.radio-control.image input:active ~ div.control-div {
  outline: 2px solid currentColor;
  background: transparent;
}
.radio-control.image:hover .remove-btn {
  display: block;
}
.radio-control.image .remove-btn {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(8px, -4px);
}

.switch-control .switch-div {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: relative;
  width: 40px;
  flex: 0 0 40px;
  height: 24px;
  color: rgb(var(--shade-40));
  background: currentColor;
  border-radius: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  box-sizing: border-box;
  transition: color 0.05s ease-in-out;
  z-index: -1;
}
.switch-control .switch-div .switch-circle {
  margin: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(var(--light));
  transition: transform 0.05s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch-control .switch-div .switch-circle .icon-off {
  display: flex;
  color: rgb(var(--dark), 0.4);
}
.switch-control .switch-div .switch-circle .icon-on {
  display: none;
  color: rgb(var(--primary));
}
.switch-control label {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
}
.switch-control input {
  width: 40px;
}
.switch-control input:focus:not(:active) ~ .switch-div {
  outline: 2px solid rgb(var(--primary-20));
  outline-offset: -2px;
}
.switch-control input:checked ~ .switch-div {
  background: rgb(var(--primary));
}
.switch-control input:checked ~ .switch-div .switch-circle {
  transform: translateX(16px);
}
.switch-control input:checked ~ .switch-div .switch-circle .icon-off {
  display: none;
}
.switch-control input:checked ~ .switch-div .switch-circle .icon-on {
  display: flex;
}
.switch-control input:hover ~ .switch-div {
  background: linear-gradient(rgb(var(--dark), 0.2), rgb(var(--dark), 0.2)), linear-gradient(rgb(var(--shade-40)), rgb(var(--shade-40)));
}
.switch-control input:hover:checked ~ .switch-div {
  background: linear-gradient(rgb(var(--dark), 0.2), rgb(var(--dark), 0.2)), linear-gradient(rgb(var(--primary)), rgb(var(--primary)));
}
.switch-control input:active ~ .switch-div {
  background: linear-gradient(rgb(var(--dark), 0.4), rgb(var(--dark), 0.4)), linear-gradient(rgb(var(--shade-40)), rgb(var(--shade-40)));
}
.switch-control input:active:checked ~ .switch-div {
  background: linear-gradient(rgb(var(--dark), 0.4), rgb(var(--dark), 0.4)), linear-gradient(rgb(var(--primary)), rgb(var(--primary)));
}
.switch-control input:disabled ~ .switch-div {
  background: rgb(var(--shade-40));
  opacity: 0.4;
}
.switch-control input:disabled:checked ~ .switch-div {
  background: rgb(var(--primary));
  opacity: 0.4;
}
.switch-control.ltr {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.switch-control.ltr .switch-div {
  margin-right: 12px;
}
.switch-control.rtl {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}
.switch-control.rtl .switch-div {
  margin-left: 12px;
  -webkit-box-ordinal-group: 12;
  -moz-box-ordinal-group: 12;
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.uploadfile-control {
  margin-bottom: 40px;
  display: block;
}
.uploadfile-control .upload-box {
  min-height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f5f5f5;
  padding: 24px;
}
.uploadfile-control.dragover .upload-box {
  opacity: 0.7;
}
.uploadfile-control label {
  padding-bottom: 16px;
  display: block;
}
.uploadfile-control .placeholder {
  text-decoration: underline;
  font-size: 18px;
  color: #6A6C72;
  cursor: pointer;
}
.uploadfile-control.hasfiles .placeholder {
  display: none;
}
.uploadfile-control.hasfiles .upload-box {
  justify-content: flex-start;
}
.uploadfile-control.hasfiles .file-list {
  display: block;
}
.uploadfile-control .error {
  color: rgb(var(--danger));
  margin-top: 4px;
}
.uploadfile-control .file-list {
  list-style-type: none;
  margin: 0;
  padding: 0 24px;
  flex: 1;
  display: none;
}
.uploadfile-control .file-list li {
  display: flex;
}
.uploadfile-control .file-list .file {
  flex: 0 1 30%;
}
.uploadfile-control .file-list .delete-file {
  font-size: 0.8em;
}
.uploadfile-control .file-list .delete-file i {
  font-size: 0.8em;
}

.range-slider {
  display: inline-block;
}
.range-slider label {
  display: block;
}
.range-slider .slider-wrapper {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.range-slider .slider-wrapper span {
  padding-left: 8px;
  white-space: nowrap;
}
.range-slider .slider {
  position: relative;
  width: 100%;
  height: 24px;
}
.range-slider .slider input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  color: inherit;
  background: transparent;
  cursor: pointer;
  position: relative;
}
.range-slider .slider input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.range-slider .slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: currentColor;
}
.range-slider .slider input[type=range]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: currentColor;
}
.range-slider .slider input[type=range]::-ms-thumb {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: currentColor;
}
.range-slider .slider input[type=range]:focus {
  outline: none;
}
.range-slider .slider progress {
  pointer-events: none;
  color: inherit;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.range-slider .slider progress::-webkit-progress-value {
  transition: none;
}
.range-slider .slider.disabled {
  color: rgb(var(--shade-20));
}
.range-slider .slider.disabled progress::-webkit-progress-bar {
  background-color: rgb(var(--shade-5));
}
.range-slider .slider.disabled progress::-moz-progress-bar {
  background-color: rgb(var(--shade-5));
}
.range-slider .slider.primary progress::-webkit-progress-bar {
  background-color: rgb(var(--primary-10));
}
.range-slider .slider.primary progress::-moz-progress-bar {
  background-color: rgb(var(--primary-10));
}
.range-slider .slider.primary input[type=range] {
  color: rgb(var(--primary));
}
.range-slider .slider.primary input[type=range]:hover::-webkit-slider-thumb {
  background: rgb(var(--primary-20));
}
.range-slider .slider.primary input[type=range]:hover::-moz-range-thumb {
  background: rgb(var(--primary-20));
}
.range-slider .slider.primary input[type=range]:hover::-ms-thumb {
  background: rgb(var(--primary-20));
}
.range-slider .slider.primary input[type=range]:focus::-webkit-slider-thumb {
  background: rgb(var(--primary-30));
}
.range-slider .slider.primary input[type=range]:focus::-moz-range-thumb {
  background: rgb(var(--primary-30));
}
.range-slider .slider.primary input[type=range]:focus::-ms-thumb {
  background: rgb(var(--primary-30));
}
.range-slider .slider.danger progress::-webkit-progress-bar {
  background-color: rgb(var(--danger-10));
}
.range-slider .slider.danger progress::-moz-progress-bar {
  background-color: rgb(var(--danger-10));
}
.range-slider .slider.danger input[type=range] {
  color: rgb(var(--danger));
}
.range-slider .slider.danger input[type=range]:hover::-webkit-slider-thumb {
  background: rgb(var(--danger-20));
}
.range-slider .slider.danger input[type=range]:hover::-moz-range-thumb {
  background: rgb(var(--danger-20));
}
.range-slider .slider.danger input[type=range]:hover::-ms-thumb {
  background: rgb(var(--danger-20));
}
.range-slider .slider.danger input[type=range]:focus::-webkit-slider-thumb {
  background: rgb(var(--danger-30));
}
.range-slider .slider.danger input[type=range]:focus::-moz-range-thumb {
  background: rgb(var(--danger-30));
}
.range-slider .slider.danger input[type=range]:focus::-ms-thumb {
  background: rgb(var(--danger-30));
}
.range-slider .slider.warning progress::-webkit-progress-bar {
  background-color: rgb(var(--warning-10));
}
.range-slider .slider.warning progress::-moz-progress-bar {
  background-color: rgb(var(--warning-10));
}
.range-slider .slider.warning input[type=range] {
  color: rgb(var(--warning));
}
.range-slider .slider.warning input[type=range]:hover::-webkit-slider-thumb {
  background: rgb(var(--warning-20));
}
.range-slider .slider.warning input[type=range]:hover::-moz-range-thumb {
  background: rgb(var(--warning-20));
}
.range-slider .slider.warning input[type=range]:hover::-ms-thumb {
  background: rgb(var(--warning-20));
}
.range-slider .slider.warning input[type=range]:focus::-webkit-slider-thumb {
  background: rgb(var(--warning-30));
}
.range-slider .slider.warning input[type=range]:focus::-moz-range-thumb {
  background: rgb(var(--warning-30));
}
.range-slider .slider.warning input[type=range]:focus::-ms-thumb {
  background: rgb(var(--warning-30));
}
.range-slider .slider.success progress::-webkit-progress-bar {
  background-color: rgb(var(--success-10));
}
.range-slider .slider.success progress::-moz-progress-bar {
  background-color: rgb(var(--success-10));
}
.range-slider .slider.success input[type=range] {
  color: rgb(var(--success));
}
.range-slider .slider.success input[type=range]:hover::-webkit-slider-thumb {
  background: rgb(var(--success-20));
}
.range-slider .slider.success input[type=range]:hover::-moz-range-thumb {
  background: rgb(var(--success-20));
}
.range-slider .slider.success input[type=range]:hover::-ms-thumb {
  background: rgb(var(--success-20));
}
.range-slider .slider.success input[type=range]:focus::-webkit-slider-thumb {
  background: rgb(var(--success-30));
}
.range-slider .slider.success input[type=range]:focus::-moz-range-thumb {
  background: rgb(var(--success-30));
}
.range-slider .slider.success input[type=range]:focus::-ms-thumb {
  background: rgb(var(--success-30));
}

.discrete:hover::before, .outline:hover::before {
  background-color: currentColor;
  opacity: 0.1;
}

.discrete:active::before, .outline:active::before {
  background-color: currentColor;
  opacity: 0.2;
}

.btn:focus {
  box-shadow: 0 0 0 2px inset rgb(var(--primary-20));
}

.btn, button {
  background-color: transparent;
  position: relative;
  font-family: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  color: inherit;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  padding: 0;
}
.btn > *, button > * {
  pointer-events: none;
}

button {
  font-size: inherit;
}
button::-moz-focus-inner {
  border: 0;
}

.btn {
  font-weight: 500;
  border-radius: 4px;
}
.btn [class*=connect_icon] {
  vertical-align: middle;
}
.btn.ellipsis {
  max-width: 100%;
}
.btn.sm {
  font-size: 14px;
  line-height: 20px;
  padding: 6px 16px;
}
.btn.sm.pill {
  border-radius: 32px;
}
.btn.sm.solid, .btn.sm.outline, .btn.sm.discrete {
  min-width: 64px;
}
.btn.sm.icon, .btn.sm.btn-icon {
  min-width: 32px;
  min-height: 32px;
  padding: 0;
}
.btn.md {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
}
.btn.md.pill {
  border-radius: 36px;
}
.btn.md.solid, .btn.md.outline, .btn.md.discrete {
  min-width: 72px;
}
.btn.md.icon, .btn.md.btn-icon {
  min-width: 36px;
  min-height: 36px;
  padding: 0;
}
.btn.lg {
  font-size: 16px;
  line-height: 22px;
  padding: 9px 16px;
}
.btn.lg.pill {
  border-radius: 40px;
}
.btn.lg.solid, .btn.lg.outline, .btn.lg.discrete {
  min-width: 80px;
}
.btn.lg.icon, .btn.lg.btn-icon {
  min-width: 40px;
  min-height: 40px;
  padding: 0;
}
.btn.xl {
  font-size: 16px;
  line-height: 22px;
  padding: 11px 16px;
}
.btn.xl.pill {
  border-radius: 44px;
}
.btn.xl.solid, .btn.xl.outline, .btn.xl.discrete {
  min-width: 88px;
}
.btn.xl.icon, .btn.xl.btn-icon {
  min-width: 44px;
  min-height: 44px;
  padding: 0;
}
.btn .btn-label {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.btn .btn-label :not(ui-icon) {
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}
.btn .ui-icon.left {
  margin-right: 4px;
}
.btn .ui-icon.right {
  margin-left: 4px;
}
.btn[class][disabled] {
  pointer-events: none;
  background-color: rgb(var(--shade-5));
  color: rgb(var(--shade-50));
  box-shadow: none;
}
.solid {
  color: rgb(var(--light));
}
.solid:hover::before {
  background: rgb(var(--dark), 0.2);
}
.solid:active::before {
  background: rgb(var(--dark), 0.4);
}

.outline {
  box-shadow: 0 0 0 1px inset;
}
a.btn.link, .btn.link {
  display: inline-block;
  padding: initial;
  line-height: inherit;
}
a.btn.link:focus, a.btn.link:hover, .btn.link:focus, .btn.link:hover {
  box-shadow: none;
  text-decoration: underline;
}
a.btn.link[disabled], .btn.link[disabled] {
  background-color: transparent;
}
a.btn.link i:before, a.btn.link [class*=connect_icon]:before, .btn.link i:before, .btn.link [class*=connect_icon]:before {
  display: inline-block;
}

.btn.loading {
  pointer-events: none;
  user-select: none;
}
.btn.loading .btn-label, .btn.loading .btn-icon.mid {
  color: transparent;
}
.btn.loading:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.7em;
  margin-left: -0.7em;
  font-size: 1.1em;
  border-width: 0.2em;
  border-style: solid;
  border-top-color: transparent !important;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  box-sizing: content-box;
}
.btn.loading.solid::after {
  color: rgb(var(--light));
}
.btn.loading.solid[disabled]::after {
  color: inherit;
}

a.btn {
  text-decoration: none;
  display: inline-block;
}

.dropdown {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  font-size: 1em;
}
.dropdown.open .dropdown-menu {
  opacity: 1;
  z-index: 4;
  pointer-events: initial;
  transition: all 250ms ease-out;
}
.dropdown.open .dropdown-menu.down {
  margin-top: 8px;
}
.dropdown.open .dropdown-menu.top {
  margin-bottom: 8px;
}
.dropdown.open [dropdown-trigger]:after {
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.dropdown [dropdown-trigger] {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.dropdown [dropdown-trigger]:after {
  content: "";
  display: inline-block;
  margin-left: 12px;
  margin-top: -4px;
  width: 0.5em;
  height: 0.5em;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  transition: transform 250ms ease-out;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.dropdown [dropdown-trigger].pill.outline {
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border-width: 2px;
  font-weight: bold;
}
.dropdown [dropdown-trigger].pill.outline.secondary:hover {
  background: transparent;
  color: rgb(var(--primary));
}
.dropdown .btn-icon.bg-undefined {
  color: rgb(var(--shade-60));
}
.dropdown .btn-icon.bg-undefined:hover {
  color: rgb(var(--shade));
}
.dropdown .btn-icon.bg-undefined[disabled] {
  color: rgb(var(--shade-40));
  background: none;
}
.dropdown .dropdown-menu {
  opacity: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  margin-top: 0;
}
.dropdown .dropdown-menu.down {
  top: 100%;
}
.dropdown .dropdown-menu.top {
  bottom: 100%;
}
.dropdown .dropdown-menu.right {
  right: 0;
}
.dropdown .dropdown-menu.left {
  left: 0;
}
.dropdown .dropdown-menu.center {
  left: 50%;
  transform: translateX(-50%);
}

.dropdown .dropdown-backdrop {
  background-color: var(--backdrop);
}
.dropdown .dropdown-menu {
  border-width: 0px;
  border-style: solid;
  padding: 4px 0;
  min-width: 100%;
  color: var(--dropdown-color);
  background: var(--dropdown-gradient, linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2)))), var(--dropdown-background, rgb(var(--elevation)));
  border-color: var(--dropdown-divider);
  border-radius: 4px;
  box-shadow: var(--dropdown-shadow);
}
.dropdown .dropdown-menu button {
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 8px 24px;
  line-height: -16px;
}
.dropdown .dropdown-menu button:hover {
  background: rgb(var(--primary), 0.05);
}
.dropdown .dropdown-menu button:focus {
  background: rgb(var(--primary), 0.02);
}

.progressive-placeholder .actions, .progressive-placeholder button, .progressive-placeholder .description1, .progressive-placeholder .description2, .progressive-placeholder .main-title, .progressive-placeholder .sub-title, .progressive-placeholder .thumb {
  background: linear-gradient(to right, rgb(var(--shade-10)) 8%, rgb(var(--shade-20)) 18%, rgb(var(--shade-10)) 33%);
  -webkit-background-size: 800px 104px;
  background-size: 1200px 104px;
  -webkit-animation: gradient 1.25s infinite; /* Safari 4+ */
  -moz-animation: gradient 1.25s infinite; /* Fx 5+ */
  -o-animation: gradient 1.25s infinite; /* Opera 12+ */
  animation: gradient 1.25s infinite; /* IE 10+, Fx 29+ */
}

.progressive-placeholder {
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgb(var(--elevation));
  border-radius: 4px;
  -webkit-animation: gradient 1.25s infinite; /* Safari 4+ */
  -moz-animation: gradient 1.25s infinite; /* Fx 5+ */
  -o-animation: gradient 1.25s infinite; /* Opera 12+ */
  animation: gradient 1.25s infinite; /* IE 10+, Fx 29+ */
}
.progressive-placeholder .progressive-header {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.progressive-placeholder .progressive-header .title {
  width: 100%;
}
.progressive-placeholder .progressive-header .main-title {
  width: 40%;
}
.progressive-placeholder .progressive-header .sub-title {
  width: 65%;
}
.progressive-placeholder .progressive-content {
  max-width: 100%;
}
.progressive-placeholder .progressive-content .description1 {
  width: 100%;
}
.progressive-placeholder .progressive-content .description2 {
  width: 90%;
}
.progressive-placeholder .description1, .progressive-placeholder .description2, .progressive-placeholder .main-title, .progressive-placeholder .sub-title, .progressive-placeholder .thumb {
  min-height: 10px;
  line-height: 0;
}
.progressive-placeholder .thumb {
  font-size: 48px;
  width: 1em;
  height: 1em;
  flex-basis: 1em;
  flex-shrink: 0;
  border-radius: 1em;
}
.progressive-placeholder button {
  min-width: 120px;
  height: 32px;
  border-radius: 4px;
  cursor: default;
}
.progressive-placeholder .actions {
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 1em;
}
.progressive-placeholder .actions::before, .progressive-placeholder .actions::after {
  content: " ";
  display: block;
  background: inherit;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 1em;
}
.progressive-placeholder .actions::before {
  transform: translate(0px, -2em);
}
.progressive-placeholder .actions::after {
  transform: translate(0px, 1em);
}

.sliding-panes {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.sliding-panes .sliding-pane {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 350ms ease-out;
  transform: translateX(0);
}
.sliding-panes .sliding-pane.slide-left {
  transform: translateX(-100%);
  position: absolute;
  top: 0;
}
.sliding-panes .sliding-pane.slide-right {
  transform: translateX(100%);
  position: absolute;
  top: 0;
}

.steps {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: stretch;
  -moz-justify-content: stretch;
  -ms-justify-content: stretch;
  justify-content: stretch;
  -ms-flex-pack: stretch;
  -webkit-box-flex: 1 1 100%;
  -moz-box-flex: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}
.steps progress {
  flex: 0 0 4px;
  transition: opacity 200ms ease-out;
}
.steps progress.hidden {
  opacity: 0;
}
.steps .steps-nav {
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.steps .steps-nav.inactive {
  display: none;
}
.steps .steps-nav .skip-btn {
  margin-left: auto;
}
.steps .steps-nav > .btn {
  background: transparent;
}
.steps .modal-footer {
  text-align: right;
}
.steps .modal-content {
  min-height: 100%;
  position: relative;
}
.steps .steps-viewport {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-flex: 1 1 100%;
  -moz-box-flex: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.steps .step {
  width: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: stretch;
  -moz-justify-content: stretch;
  -ms-justify-content: stretch;
  justify-content: stretch;
  -ms-flex-pack: stretch;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
}
.steps .step[hidden] {
  visibility: hidden;
}
.steps .step .step-actions {
  padding-top: 16px;
}
.steps .step .step-actions button {
  padding: 0;
}
.steps .step .step-actions button.cancel {
  margin-left: -4px;
}
.steps .step .step-actions + .modal-body {
  margin-top: 0;
  margin-bottom: auto;
}
.steps .step .step-content {
  height: 100%;
}

.alert {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  padding: 16px;
  border-radius: 4px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
}
.alert.default {
  background: rgb(var(--elevation));
  border-color: var(--divider);
}
.alert .alert-thumb, .alert .alert-details, .alert .alert-actions {
  padding: 8px;
}
.alert .alert-details {
  -webkit-box-flex: 1 1 0%;
  -moz-box-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  color: inherit;
  max-width: 100%;
}
.alert .alert-details p {
  margin: 0;
  color: inherit;
}
.alert .alert-close {
  color: inherit;
  padding: 0;
  margin-right: -8px;
}
.alert .alert-actions {
  white-space: nowrap;
}
@media (max-width: 428px) {
  .alert .alert-details, .alert .alert-actions {
    order: 12;
    flex-basis: 100%;
  }
  .alert .alert-close {
    margin-left: auto;
  }
}
.alert.primary {
  background: rgb(var(--primary-5));
  border-color: rgb(var(--primary-10));
  color: rgb(var(--shade));
}
.alert.primary a {
  color: inherit;
}
.alert.danger {
  background: rgb(var(--danger-5));
  border-color: rgb(var(--danger-10));
  color: rgb(var(--shade));
}
.alert.danger a {
  color: inherit;
}
.alert.warning {
  background: rgb(var(--warning-5));
  border-color: rgb(var(--warning-10));
  color: rgb(var(--shade));
}
.alert.warning a {
  color: inherit;
}
.alert.success {
  background: rgb(var(--success-5));
  border-color: rgb(var(--success-10));
  color: rgb(var(--shade));
}
.alert.success a {
  color: inherit;
}

.empty-state {
  width: 100%;
  color: rgb(var(--shade));
  color: inherit;
}
.empty-state .empty-state-content {
  padding: 16px;
  margin: auto;
  text-align: center;
}
.empty-state .title, .empty-state p {
  margin-top: 16px;
  margin-bottom: 16px;
}
.empty-state .title, .empty-state .details, .empty-state p, .empty-state i {
  color: inherit;
  white-space: pre-wrap;
}
.empty-state .btn {
  min-width: 120px;
}
.empty-state .empty-state-image {
  max-width: 100%;
}
.empty-state.page {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  height: 100%;
}
.empty-state.page .empty-state-icon, .empty-state.page .icon {
  font-size: 3em;
}
.empty-state.card .empty-state-icon {
  font-size: 2em;
}

progress {
  width: 100%;
  height: 4px;
}
progress::-webkit-progress-value {
  transition: width 250ms ease-out;
  -webkit-transition: width 250ms ease-out;
}

progress[value] {
  width: 100%;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  max-height: 4px;
}
progress[value]::-webkit-progress-bar {
  background-color: rgb(var(--shade-20));
  border-radius: 4px;
}
progress[value]::-webkit-progress-value {
  background-color: currentColor;
  border-radius: 4px;
}
progress[value]::-moz-progress-bar {
  background-color: currentColor;
  border-radius: 4px;
}

.expandable-list {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
}
.expandable-list.expanded .expandable-element:not(.expanded) {
  opacity: 0.5;
}
.expandable-list .expandable-element {
  height: 128px;
  padding: 0 12px;
  position: static;
  margin-top: -1px;
  transition: margin-bottom 0.4s ease-out, opacity 0.2s;
}
.expandable-list .expandable-element .trigger {
  height: 128px;
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1px solid rgb(var(--shade-20));
  border-top: 1px solid rgb(var(--shade-20));
  font-size: 18px;
  padding-right: 40px;
  cursor: pointer;
}
.expandable-list .expandable-element .trigger .caret {
  position: absolute;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  color: rgb(var(--shade-60));
  font-size: 16px;
}
.expandable-list .expandable-element .trigger .caret:before {
  transform: rotate(-90deg);
  transition: transform 250ms ease-out;
}
.expandable-list .expandable-element .content {
  box-sizing: border-box;
  position: absolute;
  left: 12px;
  right: 12px;
  background-color: rgb(var(--shade-5));
  align-items: center;
  padding: 0 40px;
  overflow: hidden;
  margin-top: -1px;
  transition: height 0.4s ease-out;
  display: flex;
  align-items: center;
}
.expandable-list .expandable-element .content div {
  width: 100%;
  overflow-wrap: break-word;
}
@media (max-width: 428px) {
  .expandable-list .expandable-element .content {
    padding: 0 20px;
  }
}
.expandable-list .expandable-element.expanded .trigger {
  border-top: 1px solid rgb(var(--primary));
  font-weight: bold;
}
.expandable-list .expandable-element.expanded .trigger .caret:before {
  transform: rotate(90deg);
}

ol.breadcrumb {
  list-style-type: none;
  display: flex;
  margin: 0 0 32px 0;
}
ol.breadcrumb li {
  padding: 0;
}
ol.breadcrumb li:before {
  font-family: "connect-font";
  font-size: 10px;
  vertical-align: bottom;
  transform: rotate(180deg);
  content: "\e90d";
  display: inline-block;
  margin: -1px 4px;
  color: #005eff;
}
ol.breadcrumb li a {
  cursor: pointer;
  color: #005eff;
}

.pagination {
  display: flex;
  align-items: center;
  margin: 64px 0 64px 0;
  padding: 24px;
  position: relative;
}
.pagination .label {
  font-size: 18px;
}
.pagination .ngx-pagination {
  margin: 0 0 0 30px;
  padding: 0;
}
.pagination .ngx-pagination li {
  width: 40px;
  height: 36px;
  line-height: 35px;
  font-size: 16px;
  color: rgb(var(--shade-80));
  border-radius: 4px;
  border: 1px solid rgb(var(--shade-60));
  margin-right: 16px;
  text-align: center;
  padding: 0;
  overflow: visible;
}
.pagination .ngx-pagination li a {
  padding: 0;
  color: rgb(var(--shade));
}
.pagination .ngx-pagination li a:hover {
  background: none;
}
.pagination .ngx-pagination li a:focus {
  outline: none;
}
.pagination .ngx-pagination li.current {
  background-color: rgb(var(--primary));
  border-color: rgb(var(--primary));
  color: rgb(var(--light));
}
.pagination .ngx-pagination .pagination-previous,
.pagination .ngx-pagination .pagination-next {
  display: none;
  visibility: collapse;
}

.popover .popover-trigger:after {
  color: var(--popover-background);
}
.popover [popover-content] {
  background-color: var(--popover-background);
  border-radius: 4px;
  box-shadow: var(--popover-shadow);
  color: var(--popover-color);
  font-size: 14px;
  line-height: 1.375em;
  padding: 12px;
  letter-spacing: normal;
  font-weight: normal;
}

.popover {
  position: relative;
  overflow: visible;
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.popover.left, .popover.right {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.popover.start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.popover.end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}
.popover.center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.popover.open {
  z-index: 100;
}
.popover.open .popover-trigger:after {
  display: block;
}
.popover .popover-wrapper {
  position: absolute;
}
.popover.top .popover-wrapper {
  bottom: 100%;
  margin-bottom: 8px;
}
.popover.top .popover-trigger:after {
  bottom: 100%;
  margin-bottom: -8px;
}
.popover.right .popover-wrapper {
  left: 100%;
  margin-left: 8px;
}
.popover.right .popover-trigger:after {
  left: 100%;
  margin-left: -8px;
}
.popover.bottom .popover-wrapper {
  top: 100%;
  margin-top: 8px;
}
.popover.bottom .popover-trigger:after {
  top: 100%;
  margin-top: -8px;
}
.popover.left .popover-wrapper {
  right: 100%;
  margin-right: 8px;
}
.popover.left .popover-trigger:after {
  right: 100%;
  margin-right: -8px;
}
.popover.top .popover-trigger:after, .popover.bottom .popover-trigger:after {
  left: 50%;
  transform: translateX(-50%);
}
.popover.left .popover-trigger:after, .popover.right .popover-trigger:after {
  top: 50%;
  transform: translateY(-50%);
}
.popover .popover-trigger:after {
  content: "";
  display: none;
  position: absolute;
  width: 8px;
  height: 8px;
  border-width: 8px;
  box-sizing: border-box;
  border-style: solid;
  z-index: 1;
}
.popover.top .popover-trigger:after {
  border-color: currentColor transparent transparent transparent;
}
.popover.right .popover-trigger:after {
  border-color: transparent currentColor transparent transparent;
}
.popover.bottom .popover-trigger:after {
  border-color: transparent transparent currentColor transparent;
}
.popover.left .popover-trigger:after {
  border-color: transparent transparent transparent currentColor;
}

.well {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background: rgb(var(--shade-5));
  border-radius: 4px;
}
.well:not(.unbordered) {
  border: 1px solid rgb(var(--shade-5));
}

.chips .chip {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 8px 16px;
  background: rgb(var(--elevation));
  border: 1px solid var(--divider);
  border-radius: 8px;
}
.chips .chip.selected {
  background: rgb(var(--primary), 0.05);
  border-color: rgb(var(--primary));
}
.chips .chip.selected.danger {
  background: transparent;
  border-color: rgb(var(--danger));
}
.chips .chip.selected.success {
  background: rgb(var(--success), 0.05);
  border-color: rgb(var(--success));
}

.loader {
  width: 100%;
  min-width: 1em;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  font-size: 16px;
}
.loader.primary {
  color: rgb(var(--primary));
}
.loader.vertical {
  -webkit-flex-flow: column wrap;
  -moz-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}
.loader.size-2x {
  font-size: 32px;
}
.loader.size-3x {
  font-size: 48px;
}
.loader.size-4x {
  font-size: 64px;
}
.loader.size-5x {
  font-size: 80px;
}
.loader.size-11x {
  font-size: 175px;
}
.loader.absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.loader.absolute:before {
  opacity: 0.8;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.loader .spinner {
  color: inherit;
  position: relative;
  border: 0.16em solid currentColor;
  border-top-color: transparent;
  border-radius: 50%;
  max-width: 1em;
  height: 1em;
  flex: 0 0 1em;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;
  margin-left: 8px;
  margin-right: 8px;
  box-sizing: border-box;
}
.loader p {
  position: relative;
  font-size: 14px;
  margin: 0.5em 0;
  color: inherit;
}

.loader .data-protection {
  display: block;
  width: 1em;
  height: 1em;
  position: relative;
}
.loader .data-protection:before, .loader .data-protection:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.loader .data-protection:before {
  background-image: url("/ux/images/privacy/scan@2X.png");
  -webkit-animation: spin 2000ms linear infinite; /* Safari 4+ */
  -moz-animation: spin 2000ms linear infinite; /* Fx 5+ */
  -o-animation: spin 2000ms linear infinite; /* Opera 12+ */
  animation: spin 2000ms linear infinite; /* IE 10+, Fx 29+ */
  background-size: 65%;
}
.loader .data-protection:after {
  -webkit-animation: spin-reverse 4000ms linear infinite; /* Safari 4+ */
  -moz-animation: spin-reverse 4000ms linear infinite; /* Fx 5+ */
  -o-animation: spin-reverse 4000ms linear infinite; /* Opera 12+ */
  animation: spin-reverse 4000ms linear infinite; /* IE 10+, Fx 29+ */
  background-image: url("/ux/images/privacy/dpyRing@2x.png");
  background-size: cover;
}

.block-btn {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
  padding: 16px;
  text-align: left;
  white-space: normal;
  line-height: 1em;
}
.block-btn img {
  max-width: 80px;
  max-height: 80px;
}
@media (max-width: 428px) {
  .block-btn img {
    max-width: 40px;
    max-height: 40px;
  }
}
.block-btn h4 {
  font-size: 1.25em;
  line-height: 1.3em;
}
.block-btn:hover, .block-btn:focus {
  transform: translate(-1px, -1px);
  transition: transform 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.3);
}

.uppercase, .uppercaseFirstLetter::first-letter {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

.list-unstyled {
  list-style: inside none;
  margin: 0;
  padding: 0;
}

.italic {
  font-style: italic;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-middle {
  vertical-align: middle;
}

.text-top {
  vertical-align: text-top;
}

.text-bottom {
  vertical-align: text-bottom;
}

.wrap {
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.word-break {
  word-break: break-word;
  word-wrap: break-word;
}

.no-overflow {
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.squircle {
  border-radius: 15px 15px;
}

hr {
  color: inherit;
  border-style: solid;
  border-color: currentColor;
  border-top-width: 0;
  border-left-width: 0;
  opacity: 0.15;
  margin: 0;
}
hr.vertical {
  align-self: normal;
}
hr.dotted {
  border-style: dotted;
}
hr.dashed {
  border-style: dashed;
}

[class].d-none {
  display: none;
}

[class].d-block {
  display: block !important;
}

[class].d-inline-block {
  display: inline-block !important;
}

[class].d-inline-grid {
  display: inline-grid !important;
}

[class].d-inline {
  display: inline !important;
}

@media (min-width: 321px) {
  [class].d-xs-inline-block {
    display: inline-block;
  }
  [class].d-xs-initial {
    display: initial;
  }
  [class].d-xs-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-xs-none {
    display: none;
  }
  [class].d-xs-inline {
    display: inline;
  }
}
@media (min-width: 429px) {
  [class].d-sm-inline-block {
    display: inline-block;
  }
  [class].d-sm-initial {
    display: initial;
  }
  [class].d-sm-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-sm-none {
    display: none;
  }
  [class].d-sm-inline {
    display: inline;
  }
}
@media (min-width: 769px) {
  [class].d-md-inline-block {
    display: inline-block;
  }
  [class].d-md-initial {
    display: initial;
  }
  [class].d-md-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-md-none {
    display: none;
  }
  [class].d-md-inline {
    display: inline;
  }
}
@media (min-width: 1025px) {
  [class].d-lg-inline-block {
    display: inline-block;
  }
  [class].d-lg-initial {
    display: initial;
  }
  [class].d-lg-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-lg-none {
    display: none;
  }
  [class].d-lg-inline {
    display: inline;
  }
}
@media (min-width: 1281px) {
  [class].d-xl-inline-block {
    display: inline-block;
  }
  [class].d-xl-initial {
    display: initial;
  }
  [class].d-xl-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-xl-none {
    display: none;
  }
  [class].d-xl-inline {
    display: inline;
  }
}
@media (min-width: 1441px) {
  [class].d-xxl-inline-block {
    display: inline-block;
  }
  [class].d-xxl-initial {
    display: initial;
  }
  [class].d-xxl-flex {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  [class].d-xxl-none {
    display: none;
  }
  [class].d-xxl-inline {
    display: inline;
  }
}
.d-initial {
  display: initial !important;
}

.d-flex {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-noshrink {
  flex-shrink: 0;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.m-1 {
  margin: 8px;
}

.mt-1 {
  margin-top: 8px !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mx-1 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-2 {
  margin: 16px;
}

.mt-2 {
  margin-top: 16px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mx-2 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-3 {
  margin: 24px;
}

.mt-3 {
  margin-top: 24px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mx-3 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-4 {
  margin: 32px;
}

.mt-4 {
  margin-top: 32px !important;
}

.ml-4 {
  margin-left: 32px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.mr-4 {
  margin-right: 32px !important;
}

.my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mx-4 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-5 {
  margin: 40px;
}

.mt-5 {
  margin-top: 40px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 40px !important;
}

.my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mx-5 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-half-spas {
  margin-bottom: 4px !important;
}

.mt-half-spas {
  margin-top: 4px !important;
}

.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-0-new {
  margin: 0px;
}

.mt-0-new {
  margin-top: 0px;
}

.ml-0-new {
  margin-left: 0px;
}

.mb-0-new {
  margin-bottom: 0px;
}

.mr-0-new {
  margin-right: 0px;
}

.my-0-new {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0-new {
  margin-left: 0px;
  margin-right: 0px;
}

.m-4 {
  margin: 4px;
}

.mt-4 {
  margin-top: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.m-4-new {
  margin: 4px;
}

.mt-4-new {
  margin-top: 4px;
}

.ml-4-new {
  margin-left: 4px;
}

.mb-4-new {
  margin-bottom: 4px;
}

.mr-4-new {
  margin-right: 4px;
}

.my-4-new {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mx-4-new {
  margin-left: 4px;
  margin-right: 4px;
}

.m-8 {
  margin: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.m-8-new {
  margin: 8px;
}

.mt-8-new {
  margin-top: 8px;
}

.ml-8-new {
  margin-left: 8px;
}

.mb-8-new {
  margin-bottom: 8px;
}

.mr-8-new {
  margin-right: 8px;
}

.my-8-new {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mx-8-new {
  margin-left: 8px;
  margin-right: 8px;
}

.m-12 {
  margin: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mx-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.m-12-new {
  margin: 12px;
}

.mt-12-new {
  margin-top: 12px;
}

.ml-12-new {
  margin-left: 12px;
}

.mb-12-new {
  margin-bottom: 12px;
}

.mr-12-new {
  margin-right: 12px;
}

.my-12-new {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mx-12-new {
  margin-left: 12px;
  margin-right: 12px;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.m-16-new {
  margin: 16px;
}

.mt-16-new {
  margin-top: 16px;
}

.ml-16-new {
  margin-left: 16px;
}

.mb-16-new {
  margin-bottom: 16px;
}

.mr-16-new {
  margin-right: 16px;
}

.my-16-new {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mx-16-new {
  margin-left: 16px;
  margin-right: 16px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-20-new {
  margin: 20px;
}

.mt-20-new {
  margin-top: 20px;
}

.ml-20-new {
  margin-left: 20px;
}

.mb-20-new {
  margin-bottom: 20px;
}

.mr-20-new {
  margin-right: 20px;
}

.my-20-new {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20-new {
  margin-left: 20px;
  margin-right: 20px;
}

.m-24 {
  margin: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.m-24-new {
  margin: 24px;
}

.mt-24-new {
  margin-top: 24px;
}

.ml-24-new {
  margin-left: 24px;
}

.mb-24-new {
  margin-bottom: 24px;
}

.mr-24-new {
  margin-right: 24px;
}

.my-24-new {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mx-24-new {
  margin-left: 24px;
  margin-right: 24px;
}

.m-28 {
  margin: 28px;
}

.mt-28 {
  margin-top: 28px;
}

.ml-28 {
  margin-left: 28px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mr-28 {
  margin-right: 28px;
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px;
}

.mx-28 {
  margin-left: 28px;
  margin-right: 28px;
}

.m-28-new {
  margin: 28px;
}

.mt-28-new {
  margin-top: 28px;
}

.ml-28-new {
  margin-left: 28px;
}

.mb-28-new {
  margin-bottom: 28px;
}

.mr-28-new {
  margin-right: 28px;
}

.my-28-new {
  margin-top: 28px;
  margin-bottom: 28px;
}

.mx-28-new {
  margin-left: 28px;
  margin-right: 28px;
}

.m-32 {
  margin: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.m-32-new {
  margin: 32px;
}

.mt-32-new {
  margin-top: 32px;
}

.ml-32-new {
  margin-left: 32px;
}

.mb-32-new {
  margin-bottom: 32px;
}

.mr-32-new {
  margin-right: 32px;
}

.my-32-new {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mx-32-new {
  margin-left: 32px;
  margin-right: 32px;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-1 {
  padding: 8px !important;
}

.pt-1 {
  padding-top: 8px !important;
}

.pl-1 {
  padding-left: 8px !important;
}

.pb-1 {
  padding-bottom: 8px !important;
}

.pr-1 {
  padding-right: 8px !important;
}

.py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-1 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-2 {
  padding: 16px !important;
}

.pt-2 {
  padding-top: 16px !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.pb-2 {
  padding-bottom: 16px !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.pt-3 {
  padding-top: 24px !important;
}

.pl-3 {
  padding-left: 24px !important;
}

.pb-3 {
  padding-bottom: 24px !important;
}

.pr-3 {
  padding-right: 24px !important;
}

.py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.px-3 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-4 {
  padding: 32px !important;
}

.pt-4 {
  padding-top: 32px !important;
}

.pl-4 {
  padding-left: 32px !important;
}

.pb-4 {
  padding-bottom: 32px !important;
}

.pr-4 {
  padding-right: 32px !important;
}

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.px-4 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-5 {
  padding: 40px !important;
}

.pt-5 {
  padding-top: 40px !important;
}

.pl-5 {
  padding-left: 40px !important;
}

.pb-5 {
  padding-bottom: 40px !important;
}

.pr-5 {
  padding-right: 40px !important;
}

.py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-5 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-6 {
  padding: 48px !important;
}

.pt-6 {
  padding-top: 48px !important;
}

.pl-6 {
  padding-left: 48px !important;
}

.pb-6 {
  padding-bottom: 48px !important;
}

.pr-6 {
  padding-right: 48px !important;
}

.py-6 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.px-6 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

i,
.icon,
.device-icon,
[class*=connect_icon] {
  font-size: 1em;
}
i.size-2x,
.icon.size-2x,
.device-icon.size-2x,
[class*=connect_icon].size-2x {
  font-size: 1.5em;
}
i.size-3x,
.icon.size-3x,
.device-icon.size-3x,
[class*=connect_icon].size-3x {
  font-size: 2.25em;
}
i.size-4x,
.icon.size-4x,
.device-icon.size-4x,
[class*=connect_icon].size-4x {
  font-size: 3em;
}
i.size-5x,
.icon.size-5x,
.device-icon.size-5x,
[class*=connect_icon].size-5x {
  font-size: 3.75em;
}
i.size-6x,
.icon.size-6x,
.device-icon.size-6x,
[class*=connect_icon].size-6x {
  font-size: 4.5em;
}
i.circled,
.icon.circled,
.device-icon.circled,
[class*=connect_icon].circled {
  position: relative;
  display: flex !important;
  height: 1em;
  width: 1em;
  align-items: center;
  justify-content: center;
}
i.circled:after,
.icon.circled:after,
.device-icon.circled:after,
[class*=connect_icon].circled:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 50%;
  background-color: #f5f5f5;
}
i.circled:before,
.icon.circled:before,
.device-icon.circled:before,
[class*=connect_icon].circled:before {
  position: relative;
  z-index: 1;
  font-size: 60%;
  color: #1d252d;
}
i.circled.primary:after, i.circled.secondary:after, i.circled.warning:after, i.circled.danger:after, i.circled.success:after,
.icon.circled.primary:after,
.icon.circled.secondary:after,
.icon.circled.warning:after,
.icon.circled.danger:after,
.icon.circled.success:after,
.device-icon.circled.primary:after,
.device-icon.circled.secondary:after,
.device-icon.circled.warning:after,
.device-icon.circled.danger:after,
.device-icon.circled.success:after,
[class*=connect_icon].circled.primary:after,
[class*=connect_icon].circled.secondary:after,
[class*=connect_icon].circled.warning:after,
[class*=connect_icon].circled.danger:after,
[class*=connect_icon].circled.success:after {
  background-color: currentColor;
}
i.circled.primary:before, i.circled.secondary:before, i.circled.warning:before, i.circled.danger:before, i.circled.success:before,
.icon.circled.primary:before,
.icon.circled.secondary:before,
.icon.circled.warning:before,
.icon.circled.danger:before,
.icon.circled.success:before,
.device-icon.circled.primary:before,
.device-icon.circled.secondary:before,
.device-icon.circled.warning:before,
.device-icon.circled.danger:before,
.device-icon.circled.success:before,
[class*=connect_icon].circled.primary:before,
[class*=connect_icon].circled.secondary:before,
[class*=connect_icon].circled.warning:before,
[class*=connect_icon].circled.danger:before,
[class*=connect_icon].circled.success:before {
  color: #fff;
}

.img-fluid {
  width: 100%;
  height: auto;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.no-underline {
  text-decoration: none;
}

.text-link {
  color: inherit;
}

figure {
  margin: 0;
}
figure img {
  display: block;
}
figure.img-responsive {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
figure.img-responsive[class*=ratio-]:before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
figure.img-responsive[class*=ratio-] img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  border: 0;
  transform: translate(-50%, -50%);
}
figure.img-responsive.ratio-4by3:before {
  padding-top: 75%;
}
figure.img-responsive.ratio-16by9:before {
  padding-top: 56.25%;
}
figure.img-responsive.ratio-18by9:before {
  padding-top: 50%;
}
figure.img-responsive.ratio-21by9:before {
  padding-top: 42.85%;
}
figure.img-responsive.ratio-24by9:before {
  padding-top: 37.5%;
}
figure.img-responsive.ratio-1by1:before {
  padding-top: 100%;
}
figure.img-responsive.ratio-3by4:before {
  padding-top: 133%;
}
figure.img-responsive.ratio-1by1 img, figure.img-responsive.ratio-3by4 img {
  width: 100%;
  height: auto;
}
figure.img-responsive.lazy-loaded {
  background-color: rgb(var(--shade-5));
}
figure.img-responsive.lazy-loaded img {
  white-space: nowrap;
  text-align: center;
  z-index: 1;
  color: transparent;
}
figure.img-responsive.lazy-loaded img:after {
  content: "";
  display: block;
  width: 20px;
  height: 100%;
  background-color: rgb(var(--shade-5));
  top: 0;
  position: absolute;
}
figure.img-responsive.lazy-loaded:after {
  content: "\e925";
  font-family: "connect-font";
  font-size: 42px;
  color: rgb(var(--shade-20));
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 24px;
}
figure.img-responsive.lazy-loaded:before, figure.img-responsive.lazy-loaded:after {
  background-color: inherit;
}

[class].color-inherit {
  color: inherit;
}

[class].bg-inherit {
  background-color: inherit;
}

[class].bg-none {
  background-color: transparent;
}

.focusedOnClick {
  z-index: 10;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  color: rgb(var(--shade));
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 429px) {
  h1, .h1 {
    font-size: 42px;
    line-height: 48px;
  }
  h2, .h2 {
    font-size: 36px;
    line-height: 42px;
  }
  h3, .h3 {
    font-size: 28px;
    line-height: 34px;
  }
  h4, .h4 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 428px) {
  h1, .h1 {
    font-size: 34px;
    line-height: 40px;
  }
  h2, .h2 {
    font-size: 28px;
    line-height: 34px;
  }
  h3, .h3 {
    font-size: 24px;
    line-height: 30px;
  }
  h4, .h4 {
    font-size: 20px;
    line-height: 26px;
  }
}
.h4-20 {
  font-size: 20px !important;
  line-height: 26px;
}

h5, .h5 {
  font-size: 18px;
  line-height: 24px;
}

.b1 {
  font-size: 16px;
  line-height: 22px;
}

.b2 {
  font-size: 14px;
  line-height: 20px;
}

.b3 {
  font-size: 13px;
  line-height: 18px;
}

.c1 {
  font-size: 12px;
  line-height: 16px;
}

.c2 {
  font-size: 11px;
  line-height: 14px;
}

p {
  font-size: 1em;
  line-height: 1.375em;
  -webkit-font-smoothing: antialiased;
  color: inherit;
  word-break: inherit;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.lead {
  font-size: 1.15em;
}

.strong {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.thin {
  font-weight: 300;
}

.line-height-xs {
  line-height: 1.18em;
}

app-root {
  height: 100%;
  display: block;
}

main {
  height: 100%;
}

[app-organiser] {
  overflow: hidden;
}

.pwa-alert {
  border-radius: 0;
  border: none;
  height: 0;
  max-height: 0;
  overflow: hidden;
  padding: env(safe-area-inset-top) 0 0 0;
  pointer-events: none;
}
.pwa-alert.in {
  height: auto;
  max-height: calc(120px + env(safe-area-inset-top));
  transition: max-height 350ms ease-in;
  pointer-events: auto;
  flex-shrink: 0;
  flex-basis: auto;
}

.pwa-banner {
  position: absolute;
  top: 100%;
  right: 8px;
  max-width: calc(100% - 16px);
  pointer-events: none;
}
.pwa-banner.in {
  transform: translateY(calc(-100% - 8px));
  transition: transform 350ms ease-in;
  box-shadow: 0px 4px 16px 0px rgb(var(--dark), 0.25);
  pointer-events: auto;
}

app-support-article .style-article {
  margin-bottom: 64px;
}
app-support-article .style-article h4 {
  font-size: 24px;
  margin-top: 40px;
}
app-support-article .style-article .img-responsive {
  margin-bottom: 36px;
  display: block;
  max-width: 100%;
  height: auto;
}

.logo-text {
  font-size: 21px;
  font-weight: bold;
}

.logo.hp-logo .manufacturer {
  max-width: 165px;
}

#onetrust-consent-sdk #onetrust-banner-sdk,
#onetrust-consent-sdk #onetrust-pc-sdk {
  font-size: 14px;
}
#onetrust-consent-sdk #onetrust-banner-sdk h3, #onetrust-consent-sdk #onetrust-banner-sdk h4, #onetrust-consent-sdk #onetrust-banner-sdk h5,
#onetrust-consent-sdk #onetrust-pc-sdk h3,
#onetrust-consent-sdk #onetrust-pc-sdk h4,
#onetrust-consent-sdk #onetrust-pc-sdk h5 {
  font-size: 1em !important;
  font-weight: 500 !important;
}
@media (min-width: 429px) {
  #onetrust-consent-sdk #onetrust-banner-sdk,
  #onetrust-consent-sdk #onetrust-pc-sdk {
    border-radius: 5px;
  }
}
@media (min-width: 769px) {
  #onetrust-consent-sdk #onetrust-banner-sdk,
  #onetrust-consent-sdk #onetrust-pc-sdk {
    border-radius: 8px;
  }
}
#onetrust-consent-sdk #onetrust-banner-sdk .ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-title {
  margin-top: 5px !important;
}
#onetrust-consent-sdk button#onetrust-pc-btn-handler {
  background: none !important;
  color: rgb(var(--primary)) !important;
  border-color: inherit !important;
}
#onetrust-consent-sdk button#onetrust-pc-btn-handler:hover {
  color: rgb(var(--primary-20)) !important;
}
#onetrust-consent-sdk button:not([aria-expanded]) {
  background-color: rgb(var(--primary)) !important;
  border-color: rgb(var(--primary)) !important;
  border-radius: 4px !important;
  padding: 10px 16px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
#onetrust-consent-sdk button:not([aria-expanded]):hover {
  background-color: rgb(var(--primary-20)) !important;
  border-color: rgb(var(--primary-20)) !important;
}
#onetrust-consent-sdk .ot-always-active[class] {
  color: rgb(var(--primary)) !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu[role=tab] {
  border-color: rgb(var(--primary)) !important;
}
#onetrust-consent-sdk .ot-pc-footer button {
  font-size: 14px !important;
  font-weight: 500 !important;
}
#onetrust-consent-sdk .ot-pc-footer [class].ot-pc-footer-logo {
  display: none !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk *:focus,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-vlst-cntr > a:focus {
  outline: none !important;
}
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-gv-list-handler,
#onetrust-consent-sdk .ot-grp-desc {
  font-size: 1em !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-li-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-hdr span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal #modal-header,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-checkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-ven-name,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-label-status,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-optout-signal {
  color: rgb(var(--shade-80)) !important;
}
#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-dpd-desc,
#onetrust-consent-sdk .ot-dpd-title,
#onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk #onetrust-banner-sdk #banner-options *,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-cat-header {
  color: rgb(var(--shade-80)) !important;
}

.device .badges .ui-icon {
  border: none !important;
  background: none !important;
}

.apps-installed .card.not-installed .icon-shadow {
  opacity: 50%;
}

[class].elevation-0, .pagination, app-root {
  background: linear-gradient(rgb(var(--elevation-0)), rgb(var(--elevation-0))), rgb(var(--elevation));
}

[class].elevation-1, .pagination .ngx-pagination li {
  background: linear-gradient(rgb(var(--elevation-1)), rgb(var(--elevation-1))), rgb(var(--elevation));
}

[class].elevation-2 {
  background: linear-gradient(rgb(var(--elevation-2)), rgb(var(--elevation-2))), rgb(var(--elevation));
}

[class].bg-primary-40 {
  background-color: rgb(var(--primary-40));
}
[class].color-primary-40 {
  color: rgb(var(--primary-40));
}
[class].text-primary-40 {
  color: rgb(var(--primary-40));
}

[class].bg-primary-30 {
  background-color: rgb(var(--primary-30));
}
[class].color-primary-30 {
  color: rgb(var(--primary-30));
}
[class].text-primary-30 {
  color: rgb(var(--primary-30));
}

[class].bg-primary-20 {
  background-color: rgb(var(--primary-20));
}
[class].color-primary-20 {
  color: rgb(var(--primary-20));
}
[class].text-primary-20 {
  color: rgb(var(--primary-20));
}

[class].bg-primary {
  background-color: rgb(var(--primary));
}
[class].color-primary {
  color: rgb(var(--primary));
}
[class].text-primary {
  color: rgb(var(--primary));
}

[class].bg-primary-15 {
  background-color: rgb(var(--primary-15));
}
[class].color-primary-15 {
  color: rgb(var(--primary-15));
}
[class].text-primary-15 {
  color: rgb(var(--primary-15));
}

[class].bg-primary-10 {
  background-color: rgb(var(--primary-10));
}
[class].color-primary-10 {
  color: rgb(var(--primary-10));
}
[class].text-primary-10 {
  color: rgb(var(--primary-10));
}

[class].bg-primary-5 {
  background-color: rgb(var(--primary-5));
}
[class].color-primary-5 {
  color: rgb(var(--primary-5));
}
[class].text-primary-5 {
  color: rgb(var(--primary-5));
}

[class].bg-success-40 {
  background-color: rgb(var(--success-40));
}
[class].color-success-40 {
  color: rgb(var(--success-40));
}
[class].text-success-40 {
  color: rgb(var(--success-40));
}

[class].bg-success-30 {
  background-color: rgb(var(--success-30));
}
[class].color-success-30 {
  color: rgb(var(--success-30));
}
[class].text-success-30 {
  color: rgb(var(--success-30));
}

[class].bg-success-20 {
  background-color: rgb(var(--success-20));
}
[class].color-success-20 {
  color: rgb(var(--success-20));
}
[class].text-success-20 {
  color: rgb(var(--success-20));
}

[class].bg-success {
  background-color: rgb(var(--success));
}
[class].color-success {
  color: rgb(var(--success));
}
[class].text-success {
  color: rgb(var(--success));
}

[class].bg-success-15 {
  background-color: rgb(var(--success-15));
}
[class].color-success-15 {
  color: rgb(var(--success-15));
}
[class].text-success-15 {
  color: rgb(var(--success-15));
}

[class].bg-success-10 {
  background-color: rgb(var(--success-10));
}
[class].color-success-10 {
  color: rgb(var(--success-10));
}
[class].text-success-10 {
  color: rgb(var(--success-10));
}

[class].bg-success-5 {
  background-color: rgb(var(--success-5));
}
[class].color-success-5 {
  color: rgb(var(--success-5));
}
[class].text-success-5 {
  color: rgb(var(--success-5));
}

[class].bg-danger-40 {
  background-color: rgb(var(--danger-40));
}
[class].color-danger-40 {
  color: rgb(var(--danger-40));
}
[class].text-danger-40 {
  color: rgb(var(--danger-40));
}

[class].bg-danger-30 {
  background-color: rgb(var(--danger-30));
}
[class].color-danger-30 {
  color: rgb(var(--danger-30));
}
[class].text-danger-30 {
  color: rgb(var(--danger-30));
}

[class].bg-danger-20 {
  background-color: rgb(var(--danger-20));
}
[class].color-danger-20 {
  color: rgb(var(--danger-20));
}
[class].text-danger-20 {
  color: rgb(var(--danger-20));
}

[class].bg-danger {
  background-color: rgb(var(--danger));
}
[class].color-danger {
  color: rgb(var(--danger));
}
[class].text-danger {
  color: rgb(var(--danger));
}

[class].bg-danger-15 {
  background-color: rgb(var(--danger-15));
}
[class].color-danger-15 {
  color: rgb(var(--danger-15));
}
[class].text-danger-15 {
  color: rgb(var(--danger-15));
}

[class].bg-danger-10 {
  background-color: rgb(var(--danger-10));
}
[class].color-danger-10 {
  color: rgb(var(--danger-10));
}
[class].text-danger-10 {
  color: rgb(var(--danger-10));
}

[class].bg-danger-5 {
  background-color: rgb(var(--danger-5));
}
[class].color-danger-5 {
  color: rgb(var(--danger-5));
}
[class].text-danger-5 {
  color: rgb(var(--danger-5));
}

[class].bg-warning-40 {
  background-color: rgb(var(--warning-40));
}
[class].color-warning-40 {
  color: rgb(var(--warning-40));
}
[class].text-warning-40 {
  color: rgb(var(--warning-40));
}

[class].bg-warning-30 {
  background-color: rgb(var(--warning-30));
}
[class].color-warning-30 {
  color: rgb(var(--warning-30));
}
[class].text-warning-30 {
  color: rgb(var(--warning-30));
}

[class].bg-warning-20 {
  background-color: rgb(var(--warning-20));
}
[class].color-warning-20 {
  color: rgb(var(--warning-20));
}
[class].text-warning-20 {
  color: rgb(var(--warning-20));
}

[class].bg-warning {
  background-color: rgb(var(--warning));
}
[class].color-warning {
  color: rgb(var(--warning));
}
[class].text-warning {
  color: rgb(var(--warning));
}

[class].bg-warning-15 {
  background-color: rgb(var(--warning-15));
}
[class].color-warning-15 {
  color: rgb(var(--warning-15));
}
[class].text-warning-15 {
  color: rgb(var(--warning-15));
}

[class].bg-warning-10 {
  background-color: rgb(var(--warning-10));
}
[class].color-warning-10 {
  color: rgb(var(--warning-10));
}
[class].text-warning-10 {
  color: rgb(var(--warning-10));
}

[class].bg-warning-5 {
  background-color: rgb(var(--warning-5));
}
[class].color-warning-5 {
  color: rgb(var(--warning-5));
}
[class].text-warning-5 {
  color: rgb(var(--warning-5));
}

[class].bg-a1-40 {
  background-color: rgb(var(--a1-40));
}
[class].color-a1-40 {
  color: rgb(var(--a1-40));
}
[class].text-a1-40 {
  color: rgb(var(--a1-40));
}

[class].bg-a1-30 {
  background-color: rgb(var(--a1-30));
}
[class].color-a1-30 {
  color: rgb(var(--a1-30));
}
[class].text-a1-30 {
  color: rgb(var(--a1-30));
}

[class].bg-a1-20 {
  background-color: rgb(var(--a1-20));
}
[class].color-a1-20 {
  color: rgb(var(--a1-20));
}
[class].text-a1-20 {
  color: rgb(var(--a1-20));
}

[class].bg-a1 {
  background-color: rgb(var(--a1));
}
[class].color-a1 {
  color: rgb(var(--a1));
}
[class].text-a1 {
  color: rgb(var(--a1));
}

[class].bg-a1-15 {
  background-color: rgb(var(--a1-15));
}
[class].color-a1-15 {
  color: rgb(var(--a1-15));
}
[class].text-a1-15 {
  color: rgb(var(--a1-15));
}

[class].bg-a1-10 {
  background-color: rgb(var(--a1-10));
}
[class].color-a1-10 {
  color: rgb(var(--a1-10));
}
[class].text-a1-10 {
  color: rgb(var(--a1-10));
}

[class].bg-a1-5 {
  background-color: rgb(var(--a1-5));
}
[class].color-a1-5 {
  color: rgb(var(--a1-5));
}
[class].text-a1-5 {
  color: rgb(var(--a1-5));
}

[class].bg-a2-40 {
  background-color: rgb(var(--a2-40));
}
[class].color-a2-40 {
  color: rgb(var(--a2-40));
}
[class].text-a2-40 {
  color: rgb(var(--a2-40));
}

[class].bg-a2-30 {
  background-color: rgb(var(--a2-30));
}
[class].color-a2-30 {
  color: rgb(var(--a2-30));
}
[class].text-a2-30 {
  color: rgb(var(--a2-30));
}

[class].bg-a2-20 {
  background-color: rgb(var(--a2-20));
}
[class].color-a2-20 {
  color: rgb(var(--a2-20));
}
[class].text-a2-20 {
  color: rgb(var(--a2-20));
}

[class].bg-a2 {
  background-color: rgb(var(--a2));
}
[class].color-a2 {
  color: rgb(var(--a2));
}
[class].text-a2 {
  color: rgb(var(--a2));
}

[class].bg-a2-15 {
  background-color: rgb(var(--a2-15));
}
[class].color-a2-15 {
  color: rgb(var(--a2-15));
}
[class].text-a2-15 {
  color: rgb(var(--a2-15));
}

[class].bg-a2-10 {
  background-color: rgb(var(--a2-10));
}
[class].color-a2-10 {
  color: rgb(var(--a2-10));
}
[class].text-a2-10 {
  color: rgb(var(--a2-10));
}

[class].bg-a2-5 {
  background-color: rgb(var(--a2-5));
}
[class].color-a2-5 {
  color: rgb(var(--a2-5));
}
[class].text-a2-5 {
  color: rgb(var(--a2-5));
}

[class].bg-a3-40 {
  background-color: rgb(var(--a3-40));
}
[class].color-a3-40 {
  color: rgb(var(--a3-40));
}
[class].text-a3-40 {
  color: rgb(var(--a3-40));
}

[class].bg-a3-30 {
  background-color: rgb(var(--a3-30));
}
[class].color-a3-30 {
  color: rgb(var(--a3-30));
}
[class].text-a3-30 {
  color: rgb(var(--a3-30));
}

[class].bg-a3-20 {
  background-color: rgb(var(--a3-20));
}
[class].color-a3-20 {
  color: rgb(var(--a3-20));
}
[class].text-a3-20 {
  color: rgb(var(--a3-20));
}

[class].bg-a3 {
  background-color: rgb(var(--a3));
}
[class].color-a3 {
  color: rgb(var(--a3));
}
[class].text-a3 {
  color: rgb(var(--a3));
}

[class].bg-a3-15 {
  background-color: rgb(var(--a3-15));
}
[class].color-a3-15 {
  color: rgb(var(--a3-15));
}
[class].text-a3-15 {
  color: rgb(var(--a3-15));
}

[class].bg-a3-10 {
  background-color: rgb(var(--a3-10));
}
[class].color-a3-10 {
  color: rgb(var(--a3-10));
}
[class].text-a3-10 {
  color: rgb(var(--a3-10));
}

[class].bg-a3-5 {
  background-color: rgb(var(--a3-5));
}
[class].color-a3-5 {
  color: rgb(var(--a3-5));
}
[class].text-a3-5 {
  color: rgb(var(--a3-5));
}

[class].bg-a4-40 {
  background-color: rgb(var(--a4-40));
}
[class].color-a4-40 {
  color: rgb(var(--a4-40));
}
[class].text-a4-40 {
  color: rgb(var(--a4-40));
}

[class].bg-a4-30 {
  background-color: rgb(var(--a4-30));
}
[class].color-a4-30 {
  color: rgb(var(--a4-30));
}
[class].text-a4-30 {
  color: rgb(var(--a4-30));
}

[class].bg-a4-20 {
  background-color: rgb(var(--a4-20));
}
[class].color-a4-20 {
  color: rgb(var(--a4-20));
}
[class].text-a4-20 {
  color: rgb(var(--a4-20));
}

[class].bg-a4 {
  background-color: rgb(var(--a4));
}
[class].color-a4 {
  color: rgb(var(--a4));
}
[class].text-a4 {
  color: rgb(var(--a4));
}

[class].bg-a4-15 {
  background-color: rgb(var(--a4-15));
}
[class].color-a4-15 {
  color: rgb(var(--a4-15));
}
[class].text-a4-15 {
  color: rgb(var(--a4-15));
}

[class].bg-a4-10 {
  background-color: rgb(var(--a4-10));
}
[class].color-a4-10 {
  color: rgb(var(--a4-10));
}
[class].text-a4-10 {
  color: rgb(var(--a4-10));
}

[class].bg-a4-5 {
  background-color: rgb(var(--a4-5));
}
[class].color-a4-5 {
  color: rgb(var(--a4-5));
}
[class].text-a4-5 {
  color: rgb(var(--a4-5));
}

[class].bg-a5-40 {
  background-color: rgb(var(--a5-40));
}
[class].color-a5-40 {
  color: rgb(var(--a5-40));
}
[class].text-a5-40 {
  color: rgb(var(--a5-40));
}

[class].bg-a5-30 {
  background-color: rgb(var(--a5-30));
}
[class].color-a5-30 {
  color: rgb(var(--a5-30));
}
[class].text-a5-30 {
  color: rgb(var(--a5-30));
}

[class].bg-a5-20 {
  background-color: rgb(var(--a5-20));
}
[class].color-a5-20 {
  color: rgb(var(--a5-20));
}
[class].text-a5-20 {
  color: rgb(var(--a5-20));
}

[class].bg-a5 {
  background-color: rgb(var(--a5));
}
[class].color-a5 {
  color: rgb(var(--a5));
}
[class].text-a5 {
  color: rgb(var(--a5));
}

[class].bg-a5-15 {
  background-color: rgb(var(--a5-15));
}
[class].color-a5-15 {
  color: rgb(var(--a5-15));
}
[class].text-a5-15 {
  color: rgb(var(--a5-15));
}

[class].bg-a5-10 {
  background-color: rgb(var(--a5-10));
}
[class].color-a5-10 {
  color: rgb(var(--a5-10));
}
[class].text-a5-10 {
  color: rgb(var(--a5-10));
}

[class].bg-a5-5 {
  background-color: rgb(var(--a5-5));
}
[class].color-a5-5 {
  color: rgb(var(--a5-5));
}
[class].text-a5-5 {
  color: rgb(var(--a5-5));
}

[class].bg-a6-40 {
  background-color: rgb(var(--a6-40));
}
[class].color-a6-40 {
  color: rgb(var(--a6-40));
}
[class].text-a6-40 {
  color: rgb(var(--a6-40));
}

[class].bg-a6-30 {
  background-color: rgb(var(--a6-30));
}
[class].color-a6-30 {
  color: rgb(var(--a6-30));
}
[class].text-a6-30 {
  color: rgb(var(--a6-30));
}

[class].bg-a6-20 {
  background-color: rgb(var(--a6-20));
}
[class].color-a6-20 {
  color: rgb(var(--a6-20));
}
[class].text-a6-20 {
  color: rgb(var(--a6-20));
}

[class].bg-a6 {
  background-color: rgb(var(--a6));
}
[class].color-a6 {
  color: rgb(var(--a6));
}
[class].text-a6 {
  color: rgb(var(--a6));
}

[class].bg-a6-15 {
  background-color: rgb(var(--a6-15));
}
[class].color-a6-15 {
  color: rgb(var(--a6-15));
}
[class].text-a6-15 {
  color: rgb(var(--a6-15));
}

[class].bg-a6-10 {
  background-color: rgb(var(--a6-10));
}
[class].color-a6-10 {
  color: rgb(var(--a6-10));
}
[class].text-a6-10 {
  color: rgb(var(--a6-10));
}

[class].bg-a6-5 {
  background-color: rgb(var(--a6-5));
}
[class].color-a6-5 {
  color: rgb(var(--a6-5));
}
[class].text-a6-5 {
  color: rgb(var(--a6-5));
}

[class].bg-a7-40 {
  background-color: rgb(var(--a7-40));
}
[class].color-a7-40 {
  color: rgb(var(--a7-40));
}
[class].text-a7-40 {
  color: rgb(var(--a7-40));
}

[class].bg-a7-30 {
  background-color: rgb(var(--a7-30));
}
[class].color-a7-30 {
  color: rgb(var(--a7-30));
}
[class].text-a7-30 {
  color: rgb(var(--a7-30));
}

[class].bg-a7-20 {
  background-color: rgb(var(--a7-20));
}
[class].color-a7-20 {
  color: rgb(var(--a7-20));
}
[class].text-a7-20 {
  color: rgb(var(--a7-20));
}

[class].bg-a7 {
  background-color: rgb(var(--a7));
}
[class].color-a7 {
  color: rgb(var(--a7));
}
[class].text-a7 {
  color: rgb(var(--a7));
}

[class].bg-a7-15 {
  background-color: rgb(var(--a7-15));
}
[class].color-a7-15 {
  color: rgb(var(--a7-15));
}
[class].text-a7-15 {
  color: rgb(var(--a7-15));
}

[class].bg-a7-10 {
  background-color: rgb(var(--a7-10));
}
[class].color-a7-10 {
  color: rgb(var(--a7-10));
}
[class].text-a7-10 {
  color: rgb(var(--a7-10));
}

[class].bg-a7-5 {
  background-color: rgb(var(--a7-5));
}
[class].color-a7-5 {
  color: rgb(var(--a7-5));
}
[class].text-a7-5 {
  color: rgb(var(--a7-5));
}

[class].bg-a8-40 {
  background-color: rgb(var(--a8-40));
}
[class].color-a8-40 {
  color: rgb(var(--a8-40));
}
[class].text-a8-40 {
  color: rgb(var(--a8-40));
}

[class].bg-a8-30 {
  background-color: rgb(var(--a8-30));
}
[class].color-a8-30 {
  color: rgb(var(--a8-30));
}
[class].text-a8-30 {
  color: rgb(var(--a8-30));
}

[class].bg-a8-20 {
  background-color: rgb(var(--a8-20));
}
[class].color-a8-20 {
  color: rgb(var(--a8-20));
}
[class].text-a8-20 {
  color: rgb(var(--a8-20));
}

[class].bg-a8 {
  background-color: rgb(var(--a8));
}
[class].color-a8 {
  color: rgb(var(--a8));
}
[class].text-a8 {
  color: rgb(var(--a8));
}

[class].bg-a8-15 {
  background-color: rgb(var(--a8-15));
}
[class].color-a8-15 {
  color: rgb(var(--a8-15));
}
[class].text-a8-15 {
  color: rgb(var(--a8-15));
}

[class].bg-a8-10 {
  background-color: rgb(var(--a8-10));
}
[class].color-a8-10 {
  color: rgb(var(--a8-10));
}
[class].text-a8-10 {
  color: rgb(var(--a8-10));
}

[class].bg-a8-5 {
  background-color: rgb(var(--a8-5));
}
[class].color-a8-5 {
  color: rgb(var(--a8-5));
}
[class].text-a8-5 {
  color: rgb(var(--a8-5));
}

[class].bg-light {
  background-color: rgb(var(--light));
}
[class].color-light {
  color: rgb(var(--light));
}
[class].text-light {
  color: rgb(var(--light));
}

[class].bg-dark {
  background-color: rgb(var(--dark));
}
[class].color-dark {
  color: rgb(var(--dark));
}
[class].text-dark {
  color: rgb(var(--dark));
}

[class].bg-brand {
  background-color: rgb(var(--brand));
}
[class].color-brand {
  color: rgb(var(--brand));
}
[class].text-brand {
  color: rgb(var(--brand));
}

[class].bg-shade {
  background-color: rgb(var(--shade));
}
[class].color-shade {
  color: rgb(var(--shade));
}
[class].text-shade {
  color: rgb(var(--shade));
}

[class].bg-elevation {
  background-color: rgb(var(--elevation));
}
[class].color-elevation {
  color: rgb(var(--elevation));
}
[class].text-elevation {
  color: rgb(var(--elevation));
}

[class].bg-shade-5 {
  background-color: rgb(var(--shade-5));
}
[class].color-shade-5 {
  color: rgb(var(--shade-5));
}
[class].text-shade-5 {
  color: rgb(var(--shade-5));
}

[class].bg-shade-10 {
  background-color: rgb(var(--shade-10));
}
[class].color-shade-10 {
  color: rgb(var(--shade-10));
}
[class].text-shade-10 {
  color: rgb(var(--shade-10));
}

[class].bg-shade-20 {
  background-color: rgb(var(--shade-20));
}
[class].color-shade-20 {
  color: rgb(var(--shade-20));
}
[class].text-shade-20 {
  color: rgb(var(--shade-20));
}

[class].bg-shade-30 {
  background-color: rgb(var(--shade-30));
}
[class].color-shade-30 {
  color: rgb(var(--shade-30));
}
[class].text-shade-30 {
  color: rgb(var(--shade-30));
}

[class].bg-shade-40 {
  background-color: rgb(var(--shade-40));
}
[class].color-shade-40 {
  color: rgb(var(--shade-40));
}
[class].text-shade-40 {
  color: rgb(var(--shade-40));
}

[class].bg-shade-50 {
  background-color: rgb(var(--shade-50));
}
[class].color-shade-50 {
  color: rgb(var(--shade-50));
}
[class].text-shade-50 {
  color: rgb(var(--shade-50));
}

[class].bg-shade-60 {
  background-color: rgb(var(--shade-60));
}
[class].color-shade-60 {
  color: rgb(var(--shade-60));
}
[class].text-shade-60 {
  color: rgb(var(--shade-60));
}

[class].bg-shade-70 {
  background-color: rgb(var(--shade-70));
}
[class].color-shade-70 {
  color: rgb(var(--shade-70));
}
[class].text-shade-70 {
  color: rgb(var(--shade-70));
}

[class].bg-shade-80 {
  background-color: rgb(var(--shade-80));
}
[class].color-shade-80 {
  color: rgb(var(--shade-80));
}
[class].text-shade-80 {
  color: rgb(var(--shade-80));
}

[class].bg-shade-90 {
  background-color: rgb(var(--shade-90));
}
[class].color-shade-90 {
  color: rgb(var(--shade-90));
}
[class].text-shade-90 {
  color: rgb(var(--shade-90));
}